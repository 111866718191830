import {
  PayloadAction,
  createListenerMiddleware,
  createSlice,
} from '@reduxjs/toolkit';
import type { BaseSliceState, RootState } from '../../store/types';
import { generateBoilerplateAsyncThunkMatchers } from '../../store/helpers';

import { rtkQueryApi } from '../../store/rtk-query-api';

export interface AuthState extends BaseSliceState {
  tenantDomain: string | null;
  isAuthenticated: boolean;
  language: string;
  accessToken?: string;
}

const initialState: AuthState = {
  status: 'idle',
  tenantDomain: null,
  language: 'en',
  isAuthenticated: false,
};

const sliceId = 'auth';

export const authSlice = createSlice({
  name: sliceId,
  initialState,
  reducers: {
    setTenantDomain(state, action: PayloadAction<string>) {
      return { ...state, tenantDomain: action.payload };
    },
    clearTenantDomain(state) {
      return { ...state, tenantDomain: null };
    },
    setLanguage(state, action: PayloadAction<string>) {
      return { ...state, language: action.payload };
    },
    setAccessToken(state, action: PayloadAction<string>) {
      const accessToken = action.payload;
      state.isAuthenticated = !!accessToken;
      state.accessToken = accessToken;
    },
    signOutUser(state) {
      return {
        ...initialState,
        language: state.language,
        tenantDomain: state.tenantDomain,
      };
    },
  },
  extraReducers: (builder) => {
    generateBoilerplateAsyncThunkMatchers(builder, sliceId);
  },
});

export const selectAuth = (state: RootState) => state.auth;
export const {
  setTenantDomain,
  clearTenantDomain,
  signOutUser,
  setAccessToken,
  setLanguage,
} = authSlice.actions;
export default authSlice.reducer;

export const authListenerMiddleware = createListenerMiddleware();
authListenerMiddleware.startListening({
  actionCreator: signOutUser,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(rtkQueryApi.util.resetApiState());
  },
});
