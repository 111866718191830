import * as yup from 'yup';
import { TFunction } from 'i18next';

export const authSchema = (t: TFunction) =>
  yup.object().shape({
    email: yup
      .string()
      .email(t('Invalid email format'))
      .required(t('Email is required')),
    password: yup
      .string()
      .min(6, t('Password must be at least 8 characters'))
      .required(t('Password is required')),
  });

export const setTenantSchema = (t: TFunction) =>
  yup.object().shape({
    tenantDomain: yup.string().required(t('Fitness institution is required')),
  });

export const resetPasswordSchema = (t: TFunction) =>
  yup.object().shape({
    email: yup
      .string()
      .email(t('Invalid email format'))
      .required(t('Email is required')),
  });

export const resetPasswordConfirmSchema = (t: TFunction) =>
  yup.object().shape({
    password: yup
      .string()
      .min(8, t('Password must be at least 8 characters'))
      .required(t('Password is required')),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), undefined], t('Passwords must match'))
      .required(t('Confirm password is required')),
  });

export const signupTenantStepSchema = (t: TFunction) =>
  yup.object().shape({
    tenantName: yup.string().required(t('Venue name is required')),
    tenantDomain: yup.string().required(t('Domain is required')),
  });

export const signupAdminStepSchema = (t: TFunction) =>
  yup.object().shape({
    email: yup
      .string()
      .email(t('Invalid email format'))
      .required(t('Email is required')),
    fullName: yup.string().required(t('Full name is required')),
    password: yup
      .string()
      .min(8, t('Password must be at least 8 characters'))
      .required(t('Password is required')),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), undefined], t('Passwords must match'))
      .required(t('Confirm password is required')),
    country: yup.string().required(t('Country is required')),
    timezone: yup.string().required(t('Timezone is required')),
  });

export const signupPlanStepSchema = (t: TFunction) =>
  yup.object().shape({
    members_count: yup
      .string()
      .oneOf(
        ['50', '100', '150', '200', '300', '400', '500', 'unlimited'],
        t('Invalid members count selection'),
      )
      .required(t('Members count is required')),
    payment_interval: yup
      .string()
      .oneOf(['month', 'year'], t('Invalid payment interval selection'))
      .required(t('Payment interval is required')),
  });

export const signupTermsStepSchema = (t: TFunction) =>
  yup.object().shape({
    termsAndConditions: yup
      .bool()
      .oneOf(
        [true],
        t(
          'To complete the sign up, you must agree with our Terms of Use and Privacy Policy',
        ),
      )
      .required(),
    // newsletter: yup.bool().required(),
  });

export const languageTimeSchema = (t: TFunction) =>
  yup.object().shape({
    language: yup.string().required(t('Language is required')),
  });
