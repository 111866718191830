import { PropsWithChildren } from 'react';
import { Provider } from 'react-redux';

import './index.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './store';
import FlowbiteWrapper from './flowbite/flowbite-wrapper';
import { UserGroupProvider } from './components/hoc/UserGroupContext';

export default function Providers({ children }: PropsWithChildren) {
  return (
    <FlowbiteWrapper>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <UserGroupProvider>{children}</UserGroupProvider>
        </PersistGate>
      </Provider>
    </FlowbiteWrapper>
  );
}
