import { useTranslation } from 'react-i18next';
import { FaFileExcel } from 'react-icons/fa';

export default function NoData() {
  const { t } = useTranslation();
  return (
    <div className="flex-1 h-full w-full flex justify-center items-center py-4">
      <div className="flex flex-col items-center justify-center text-gray-500 dark:text-gray-400 opacity-20">
        <FaFileExcel className="h-40 w-40" />
        <p className="my-4 text-3xl font-semibold">{t('No data to display')}</p>
      </div>
    </div>
  );
}
