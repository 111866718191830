import { Route, Outlet } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Auth from '../features/auth';
import SignUp from '../features/signup';
import ResetPassword from '../features/reset-password';
import ResetPasswordConfirmation from '../features/reset-password/ResetPasswordConfirm';
import ProtectedRoute from '../guards/ProtectedRoute';
import WithHelperButtons from '../components/hoc/WithHelperButtons';

const RECAPTCHA_SITE_KEY = import.meta.env.VITE_RECAPTCHA_SITE_KEY;

export const authRoutes = (isAuthenticated: boolean) => {
  return (
    <Route
      element={
        <GoogleReCaptchaProvider
          useEnterprise
          reCaptchaKey={RECAPTCHA_SITE_KEY}
        >
          <WithHelperButtons>
            <Outlet />
          </WithHelperButtons>
        </GoogleReCaptchaProvider>
      }
    >
      {/* Routes available only when not authenticated */}
      <Route element={<ProtectedRoute isAllowed={!isAuthenticated} />}>
        <Route path="auth" element={<Auth />} />
      </Route>

      <Route path="sign-up" element={<SignUp />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route
        path="reset-password/confirm/:userId/:resetToken"
        element={<ResetPasswordConfirmation />}
      />
    </Route>
  );
};
