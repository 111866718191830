import React from 'react';
import { HiCheckCircle, HiXCircle } from 'react-icons/hi';

interface StatusIndicatorProps {
  flag: boolean | undefined;
}

function StatusIndicator({ flag }: StatusIndicatorProps): JSX.Element {
  return flag ? (
    <HiCheckCircle className="text-lg text-green-700" />
  ) : (
    <HiXCircle className="text-lg text-red-700" />
  );
}

export default StatusIndicator;
