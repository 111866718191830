import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import { Button, Label, TextInput } from 'flowbite-react';
import { NavLink } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';
import { resetPasswordSchema } from '../../schemas/auth.schemas';

import bgImage from '../../assets/gym1.jpg';
import Logo from '../../components/Logo';
import { setExpiryTimestamp } from '../../utils/utils';
import { useAuthPasswordResetCreateMutation } from '../../store/finegym-rtk-query-api';
import { handleUseFormServerValidationErrors } from '../../utils/useFormHelpers';

type Inputs = {
  email: string;
};

export default function ResetPassword() {
  const { t } = useTranslation();
  const [passwordReset, { isLoading }] = useAuthPasswordResetCreateMutation();
  const schema = useMemo(() => resetPasswordSchema(t), [t]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(
    async (action: string) => {
      if (!executeRecaptcha) {
        return;
      }
      await executeRecaptcha(action);
    },
    [executeRecaptcha],
  );

  const { seconds, start, isRunning } = useTimer({
    autoStart: false,
    expiryTimestamp: setExpiryTimestamp(30),
  });

  const submit = useCallback(
    async ({ email }: Inputs) => {
      await handleReCaptchaVerify('resetPassword');
      passwordReset({ passwordReset: { email } })
        .unwrap()
        .then(() => {
          toast.success(t('Password reset email sent successfully'), {
            toastId: 'password-reset-email-send-success',
          });
          start();
        })
        .catch((err) => handleUseFormServerValidationErrors({ setError })(err));
    },
    [handleReCaptchaVerify, passwordReset, setError, start, t],
  );

  return (
    <section
      className="min-h-screen bg-no-repeat bg-cover bg-center bg-gray-700 bg-blend-multiply bg-opacity-60"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen pt:mt-0">
        <Logo color="#FFFFFF" />
        <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
          <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            {t('Reset Password')}
          </h2>
          <form
            className="mt-4 space-y-4 lg:mt-5 md:space-y-5"
            onSubmit={handleSubmit(submit)}
          >
            <div>
              <Label htmlFor="email" className="block mb-2">
                {t('Your email')}
              </Label>
              <TextInput
                {...register('email')}
                type="string"
                placeholder="name@company.com"
              />
              {errors.email && (
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                  {errors.email.message}
                </p>
              )}
            </div>

            <h3 className="dark:text-white">
              {t(
                'You will receive an email with a password reset link if the account exists.',
              )}
            </h3>

            {isRunning && (
              <p className="font-light dark:text-white">
                <strong>{seconds} </strong>
                {t('seconds remaining unitl you can resubmit the request')}
              </p>
            )}

            <Button
              type="submit"
              // className="w-full text-white bg-primary-600 hover:bg-primary-700 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              className="w-full"
              isProcessing={isLoading}
              size="md"
              color="primary"
              disabled={isRunning}
            >
              <p>{t('Reset Password')}</p>
            </Button>
            <p className="text-sm font-light text-gray-500 dark:text-gray-400">
              <NavLink
                to="/"
                className="font-medium text-primary-600 hover:underline dark:text-primary-500"
              >
                {t('Back to Homepage')}
              </NavLink>
            </p>
          </form>
        </div>
      </div>
    </section>
  );
}
