import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer, {
  authListenerMiddleware,
} from '../features/auth/authSlice';
import signupReducer from '../features/signup/signupSlice';
import dashboardReducer from '../features/dashboard/dashboardSlice';
import { rtkQueryApi } from './rtk-query-api';
import customMiddleware from './middleware';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['auth'],
};

const rootReducer = combineReducers({
  auth: authReducer,
  signup: signupReducer,
  dashboard: dashboardReducer,
  [rtkQueryApi.reducerPath]: rtkQueryApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: import.meta.env.DEV,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(rtkQueryApi.middleware)
      .concat(customMiddleware)
      .prepend(authListenerMiddleware.middleware),
});

export const persistor = persistStore(store);
