import { Outlet, Navigate } from 'react-router-dom';
import NavbarSidebarLayout from '../../layouts/NavbarSidebarLayout';
import { useUsersMeRetrieveQuery } from '../../store/finegym-rtk-query-api';
import PageLoadingSpinner from '../../components/PageLoadingSpinner';

export default function DashboardWrapper() {
  // * useConnectionTestRetrieveQuery - Checks if tenant
  const { currentData: me, isLoading } = useUsersMeRetrieveQuery(undefined, {
    refetchOnMountOrArgChange: false,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });
  const isFrontdeskMode = !!me?.is_in_frontdesk_mode;

  if (isLoading) return <PageLoadingSpinner />;
  return !isFrontdeskMode ? (
    <NavbarSidebarLayout>
      <Outlet />
    </NavbarSidebarLayout>
  ) : (
    <Navigate to="/frontdesk" />
  );
}
