import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import WithSuspenseLoadingSpinner from '../components/hoc/WithSuspenseLoadingSpinner';
import PermissionGuard from '@/guards/PermissionGuard';

const AppointmentsSettingsPage = lazy(
  () => import('../features/dashboard/appointments'),
);
const AppointmentTypesPage = lazy(
  () => import('../features/dashboard/appointments/appointment-types'),
);
const AppointmentPackagesPage = lazy(
  () => import('../features/dashboard/appointments/appointment-packages'),
);
const AppointmentsListPage = lazy(
  () => import('../features/dashboard/appointments/appointments-list'),
);

export const dashboardAppointmentsRoutes = (
  <Route element={<PermissionGuard permissions={['Private sessions']} />}>
    <Route
      path="appointments"
      element={
        <WithSuspenseLoadingSpinner>
          <AppointmentsSettingsPage />
        </WithSuspenseLoadingSpinner>
      }
    >
      <Route index element={<Navigate to="scheduled" replace />} />
      <Route
        path="scheduled"
        element={
          <WithSuspenseLoadingSpinner>
            <AppointmentsListPage />
          </WithSuspenseLoadingSpinner>
        }
      />
      <Route
        path="packages"
        element={
          <WithSuspenseLoadingSpinner>
            <AppointmentPackagesPage />
          </WithSuspenseLoadingSpinner>
        }
      />
      <Route
        path="types"
        element={
          <WithSuspenseLoadingSpinner>
            <AppointmentTypesPage />
          </WithSuspenseLoadingSpinner>
        }
      />
    </Route>
  </Route>
);
