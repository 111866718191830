import { lazy } from 'react';
import { Route, Navigate } from 'react-router-dom';
import WithSuspenseLoadingSpinner from '../components/hoc/WithSuspenseLoadingSpinner';
import { dashboardStaffRoutes } from './dashboard.staff.routes';
import { dashboardMigrationsRoutes } from './dashboard.migrations.routes';
import { dashboardProductsRoutes } from './dashboard.products.routes';
import { dashboardBusinessRoutes } from './dashboard.business.routes';
import { dashboardMembershipRoutes } from './dashboard.memberships.routes';
import { dashboardScheduleRoutes } from './dashboard.schedule.routes';
import { dashboardAppointmentsRoutes } from './dashboard.appointments.routes';
import { dashboardPaymentsRoutes } from './dashboard.payments.routes';
import { dashboardReportsRoutes } from './dashboard.reports.routes';
import SubscriptionGuard from '@/components/SubscriptionGuard';

const Home = lazy(() => import('../features/dashboard/home'));
const AccountSettings = lazy(
  () => import('../features/dashboard/account-settings'),
);

export const dashboardRoutes = (
  <Route element={<SubscriptionGuard />}>
    <Route index element={<Navigate to="home" replace />} />
    <Route
      path="home"
      element={
        <WithSuspenseLoadingSpinner>
          <Home />
        </WithSuspenseLoadingSpinner>
      }
    />
    <Route
      path="account-settings"
      element={
        <WithSuspenseLoadingSpinner>
          <AccountSettings />
        </WithSuspenseLoadingSpinner>
      }
    />
    {dashboardPaymentsRoutes}
    {dashboardStaffRoutes}
    {dashboardMigrationsRoutes}
    {dashboardAppointmentsRoutes}
    {dashboardScheduleRoutes}
    {dashboardMembershipRoutes}
    {dashboardProductsRoutes}
    {dashboardBusinessRoutes}
    {dashboardReportsRoutes}
  </Route>
);
