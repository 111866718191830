import { lazy } from 'react';
import { Route, Navigate } from 'react-router-dom';
import WithSuspenseLoadingSpinner from '../components/hoc/WithSuspenseLoadingSpinner';
import DigitalDocumentListPage from '../features/dashboard/business-settings/documents';

const DigitalDocumentInfo = lazy(
  () => import('../features/dashboard/business-settings/documents/edit/info'),
);
const DigitalDocumentSignatures = lazy(
  () =>
    import('../features/dashboard/business-settings/documents/edit/signatures'),
);
const DigitalDocumentRequiredSignatures = lazy(
  () =>
    import(
      '../features/dashboard/business-settings/documents/edit/required-signatures'
    ),
);
const DigitalDocumentEdit = lazy(
  () => import('../features/dashboard/business-settings/documents/edit'),
);

export const dashboardDocumentRoutes = (
  <Route path="documents">
    <Route
      index
      element={
        <WithSuspenseLoadingSpinner>
          <DigitalDocumentListPage />
        </WithSuspenseLoadingSpinner>
      }
    />
    <Route
      path=":id"
      element={
        <WithSuspenseLoadingSpinner>
          <DigitalDocumentEdit />
        </WithSuspenseLoadingSpinner>
      }
    >
      <Route index element={<Navigate to="info" replace />} />
      <Route
        path="info"
        element={
          <WithSuspenseLoadingSpinner>
            <DigitalDocumentInfo />
          </WithSuspenseLoadingSpinner>
        }
      />
      <Route
        path="required-signatures"
        element={
          <WithSuspenseLoadingSpinner>
            <DigitalDocumentRequiredSignatures />
          </WithSuspenseLoadingSpinner>
        }
      />
      <Route
        path="signatures"
        element={
          <WithSuspenseLoadingSpinner>
            <DigitalDocumentSignatures />
          </WithSuspenseLoadingSpinner>
        }
      />
    </Route>
  </Route>
);
