export const setExpiryTimestamp = (seconds: number) => {
  const t = new Date();
  t.setSeconds(t.getSeconds() + seconds);
  return t;
};

export const hex2rgba = (hex: string, alpha = 1) => {
  const match = hex?.match(/\w\w/g);
  if (!match) return undefined;
  const [r, g, b] = match.map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

export const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});
