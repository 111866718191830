import { useState, useEffect } from 'react';
import { Alert } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { MdAnnouncement } from 'react-icons/md';
import { Link } from 'react-router-dom';
import usePlan from '../hooks/usePlan';

export default function SubscriptionAlert() {
  const { t } = useTranslation();
  const [dismissed, setDismissed] = useState(false);

  const {
    current: { isLoading, subscription },
  } = usePlan();

  useEffect(() => {
    // Reset dismissal state when the subscription status changes
    if (subscription && dismissed) setDismissed(false);
  }, [subscription, dismissed]);

  if (isLoading || !subscription || dismissed) return null;

  let message = '';
  let alertType = 'info';
  let actionLabel = '';

  // Calculate the end date if the subscription is set to cancel at the end of the period
  const cancelAtEndFormatted = subscription.cancel_at
    ? new Date(subscription.cancel_at * 1000).toLocaleDateString()
    : null;

  if (
    subscription.status === 'trialing' &&
    !subscription.default_payment_method
  ) {
    message = t(
      "You're on a free trial, but you haven't added a payment method. Please add a payment method to continue enjoying uninterrupted access.",
    );
    alertType = 'warning';
    actionLabel = t('Add Payment Method');
  } else if (subscription.status === 'past_due') {
    const gracePeriodEnd = new Date(
      subscription.current_period_end * 1000 + 14 * 24 * 60 * 60 * 1000,
    ).toLocaleDateString();
    message = `${t('Your recent payment was unsuccessful. You have until')} ${gracePeriodEnd} ${t('to update your payment method and avoid losing access.')}`;
    alertType = 'danger';
    actionLabel = t('Update Payment Method');
  } else if (subscription.status === 'canceled') {
    message = t(
      'Your subscription has been canceled due to failed payments. Please re-subscribe to regain access.',
    );
    alertType = 'danger';
    actionLabel = t('Reactivate Subscription');
  } else if (subscription.cancel_at && subscription.cancel_at_period_end) {
    message = `${t('Your subscription will end on')} ${cancelAtEndFormatted}. ${t('You can still use the application until that date.')}`;
    alertType = 'info';
    actionLabel = t('Reactivate Subscription');
  } else {
    return null; // No message to show
  }

  const handleDismiss = () => {
    setDismissed(true);
  };

  return (
    <Alert
      color={alertType}
      withBorderAccent
      onDismiss={handleDismiss}
      rounded={false}
    >
      <div className="flex items-center">
        <MdAnnouncement className="mr-2 h-5 w-5" />
        <p className="text-sm">
          {message}
          {actionLabel && (
            <>
              &nbsp;
              <Link
                to="/dashboard/business-settings/my-plan"
                className="font-medium text-primary-600 underline decoration-solid underline-offset-2 hover:no-underline dark:text-primary-500"
              >
                {actionLabel}
              </Link>
            </>
          )}
        </p>
      </div>
    </Alert>
  );
}
