/* eslint-disable i18next/no-literal-string */
import { NavLink } from 'react-router-dom';

interface LogoProps {
  color: string;
}

export default function Logo({ color }: LogoProps = { color: '#3B82F6' }) {
  return (
    <NavLink
      to="/"
      className="flex items-center mb-6 text-2xl font-semibold text-white"
    >
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        className="h-12"
        height="622.000000pt"
        viewBox="0 0 622.000000 622.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,622.000000) scale(0.100000,-0.100000)"
          fill={color}
          stroke="none"
        >
          <path
            d="M2500 6017 l0 -73 48 -13 c75 -20 130 -51 178 -103 99 -106 117 -196
112 -572 l-3 -258 40 6 c22 4 138 9 258 12 l217 6 0 292 c0 305 5 348 48 431
25 50 90 118 134 141 18 10 60 25 93 34 l60 15 3 78 3 77 -596 0 -595 0 0 -73z"
          />
          <path
            d="M3690 5226 l0 -245 58 -16 c304 -84 651 -270 874 -469 148 -131 311
-321 388 -451 13 -22 24 -41 26 -43 1 -2 33 18 71 45 37 27 125 86 196 133 70
47 127 88 127 92 0 4 -27 46 -59 95 -118 175 -308 384 -479 528 -266 224 -563
393 -858 490 -81 27 -308 85 -331 85 -10 0 -13 -53 -13 -244z"
          />
          <path
            d="M2385 5416 c-313 -103 -594 -260 -857 -477 -318 -263 -637 -714 -774
-1094 -49 -138 -109 -377 -130 -520 -24 -166 -24 -571 0 -740 76 -534 309
-1007 694 -1407 275 -285 570 -482 947 -631 77 -31 159 -60 183 -65 l42 -10 0
253 0 253 -77 32 c-414 172 -746 445 -986 810 -363 553 -429 1251 -180 1900
152 398 466 772 838 1000 91 56 263 141 343 170 l62 23 0 268 c0 148 -1 269
-2 269 -2 -1 -48 -16 -103 -34z"
          />
          <path
            d="M2987 4640 c-110 -12 -295 -53 -409 -90 -334 -111 -646 -357 -842
-662 -104 -163 -208 -431 -241 -618 -25 -145 -31 -410 -11 -556 63 -479 306
-885 714 -1193 57 -43 276 -181 287 -181 3 0 5 128 5 284 l0 284 -47 34 c-394
283 -574 848 -432 1355 108 384 365 660 724 776 152 50 164 50 1005 56 l795 6
-75 61 c-220 180 -542 343 -780 395 -223 48 -506 68 -693 49z"
          />
          <path
            d="M2840 3653 c0 -60 -3 -172 -7 -250 l-6 -143 1101 0 c1043 0 1102 1
1102 18 0 61 -70 283 -127 405 l-36 77 -1014 0 -1013 0 0 -107z"
          />
          <path
            d="M2200 2874 c0 -5 16 -20 36 -33 111 -76 196 -221 215 -368 l12 -93
186 -2 186 -3 3 -1103 2 -1102 255 0 255 0 0 1105 0 1105 503 0 c276 0 508 -4
515 -8 15 -10 -38 -95 -113 -179 -98 -111 -321 -255 -502 -324 l-63 -24 0
-262 0 -262 88 30 c113 40 317 133 410 188 190 111 404 301 533 474 160 214
267 478 313 770 l13 87 -897 0 c-493 0 -1134 3 -1423 7 -316 4 -527 3 -527 -3z"
          />
          <path
            d="M4906 1777 c-219 -308 -500 -532 -860 -687 -119 -51 -315 -120 -341
-120 -13 0 -15 -36 -15 -255 l0 -255 23 0 c12 0 74 13 137 29 480 123 874 350
1235 715 115 116 286 316 317 371 5 9 -20 29 -80 66 -48 29 -146 92 -219 141
l-131 87 -66 -92z"
          />
        </g>
      </svg>
      Finegym
    </NavLink>
  );
}
