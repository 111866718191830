import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { HiCog, HiCreditCard, HiGlobe, HiMail } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { Button } from 'flowbite-react';
import { MdImageNotSupported } from 'react-icons/md';
import {
  useBusinessSettingsRetrieveQuery,
  useConnectionTestRetrieveQuery,
  // useUsersMeRetrieveQuery,
} from '../store/finegym-rtk-query-api';
import usePlan from '../hooks/usePlan';

export default function SidebarBusinessInfoCard({
  show,
}: {
  readonly show: boolean;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: businessData } = useBusinessSettingsRetrieveQuery();
  const { data: tenantData } = useConnectionTestRetrieveQuery();

  const {
    current: { isLoading, product },
  } = usePlan();

  return (
    <div
      className={clsx([
        'text-sm text-gray-500 transition-opacity bg-white border border-gray-200 rounded-lg shadow-sm dark:text-white dark:bg-gray-700 dark:border-gray-600',
        !show && 'hidden',
      ])}
    >
      <div className="p-3">
        <div className="flex">
          <div className="me-3 shrink-0">
            <div className="block p-2 bg-gray-100 rounded-lg dark:bg-gray-800">
              {businessData?.logo ? (
                <img
                  className="w-8 h-8 rounded-sm"
                  src={businessData?.logo}
                  alt="Flowbite logo"
                />
              ) : (
                <MdImageNotSupported className="w-8 h-8 rounded-full" />
              )}
            </div>
          </div>
          <div>
            <p className="mb-1 text-base font-semibold leading-none text-gray-900 dark:text-white">
              {businessData?.company_name}
            </p>
            <p className="mb-3 text-sm font-normal">{tenantData?.domain}</p>
          </div>
        </div>

        <div>
          <ul className="text-sm my-2">
            <li className="flex items-center mb-2">
              <span className="me-2 font-semibold text-gray-400">
                <HiCreditCard className="w-3.5 h-3.5" />
              </span>
              <span className="-mt-1">
                {'Plan: '}
                &nbsp;
              </span>
              <span className="-mt-1 font-bold break-all">
                {isLoading ? t('loading...') : `${product?.name ?? 'Free'}`}
              </span>
            </li>
            {businessData?.company_website && (
              <li className="flex items-center mb-2 ">
                <span className="me-2 font-semibold text-gray-400">
                  <HiGlobe className="w-3.5 h-3.5" />
                </span>
                <a
                  href={businessData?.company_website}
                  className="text-blue-600 dark:text-blue-500 hover:underline overflow-clip text-ellipsis"
                >
                  {businessData?.company_website}{' '}
                </a>
              </li>
            )}
            {businessData?.customer_service_email && (
              <li className="flex items-start mb-2">
                <span className="me-2 font-semibold text-gray-400">
                  <HiMail className="w-3.5 h-3.5" />
                </span>
                <span className="-mt-1 break-all">
                  {businessData?.customer_service_email}
                </span>
              </li>
            )}
          </ul>
          <div className="flex flex-col gap-2">
            <Button
              type="button"
              // color="gray"
              gradientDuoTone="purpleToBlue"
              onClick={() => navigate('/dashboard/business-settings')}
            >
              <HiCog className="w-4 h-4 me-1" />
              {t('Business settings')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
