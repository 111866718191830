import { useAttendancesListQuery } from '../store/finegym-rtk-query-api';

export default function useIsMemberCheckedIn(
  memberId?: number,
  options?: { skip: boolean },
) {
  const { data: attendance, isLoading } = useAttendancesListQuery(
    { user: memberId, checkOutIsnull: true },
    { skip: !memberId || !!options?.skip },
  );
  return { isCheckedIn: !!attendance?.count, isLoading };
}
