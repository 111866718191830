import { Flowbite, useThemeMode } from 'flowbite-react';
import { PropsWithChildren, useEffect } from 'react';
import theme from './flowbite-theme';

function PersistFlowbiteThemeToLocalStorage() {
  const { mode } = useThemeMode();

  useEffect(() => {
    localStorage.setItem('theme', mode);
  }, [mode]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}

function FlowbiteWrapper({ children }: PropsWithChildren) {
  const dark = localStorage.getItem('theme') === 'dark';

  return (
    <Flowbite theme={{ mode: dark ? 'dark' : 'light', theme }}>
      <PersistFlowbiteThemeToLocalStorage />
      {children}
    </Flowbite>
  );
}

export default FlowbiteWrapper;
