import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import usePlan from '@/hooks/usePlan';

function SubscriptionGuard(): JSX.Element | null {
  const location = useLocation();
  const { t } = useTranslation();
  const {
    current: { isLoading, subscription },
  } = usePlan();

  if (isLoading) {
    return null; // Return null while loading
  }

  if (
    subscription?.status === 'canceled' &&
    location.pathname !== '/dashboard/business-settings/my-plan'
  ) {
    toast.warning(
      t(
        'Your subscription has expired. To regain full access to the application, please update your plan in the "My Plan" section.',
      ),
      {
        toastId: 'subscription-expired-warning',
      },
    );
    return <Navigate to="/dashboard/business-settings/my-plan" replace />;
  }

  return <Outlet />;
}

export default SubscriptionGuard;
