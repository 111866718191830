import { useCallback, useEffect, useMemo } from 'react';
import { selectAuth, signOutUser } from '../features/auth/authSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { useConnectionTestRetrieveQuery } from '../store/finegym-rtk-query-api';

export default function useAuth() {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth);

  // Check if tenant still exists
  const { isError } = useConnectionTestRetrieveQuery(undefined, {
    refetchOnFocus: true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  const signOut = useCallback(() => {
    dispatch(signOutUser());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      signOut();
    }
  }, [isError, signOut]);

  return useMemo(() => ({ ...auth, signOut }), [signOut, auth]);
}
