import { IDetectedBarcode, Scanner } from '@yudiel/react-qr-scanner';
import { Button } from 'flowbite-react';
import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPencilAlt, HiX } from 'react-icons/hi';
import { useAppSelector } from '../../store/hooks';
import { decodeBase64 } from '../../utils/stringUtils';
import { selectAuth } from '../auth/authSlice';
import { useMembersRetrieveQuery } from '../../store/finegym-rtk-query-api';
import CheckInHandler from '../../components/CheckInHandler';
import useIsMemberCheckedIn from '../../hooks/useIsMemberCheckedIn';

export default function FrontdeskQRCodeScanner() {
  const { t } = useTranslation();
  const [user, setUser] = useState<number>();
  const { tenantDomain } = useAppSelector(selectAuth);
  const { data: userProfile, isLoading: isFetchingUserProfile } =
    useMembersRetrieveQuery({ id: user! }, { skip: !user });

  const onDecodeCallback = useCallback(
    (detectedCodes: IDetectedBarcode[]) => {
      const decodedResult = decodeBase64(detectedCodes[0].rawValue);
      if (!!tenantDomain && decodedResult?.includes?.(tenantDomain)) {
        const userId = decodedResult.split('-')?.[2];
        if (!Number.isNaN(+userId)) {
          setUser(+userId);
        }
      }
    },
    [tenantDomain],
  );

  useEffect(
    () =>
      function cleanUp() {
        setUser(undefined);
      },
    [],
  );

  const { isCheckedIn, isLoading: isLoadingCheckedInState } =
    useIsMemberCheckedIn(userProfile?.id, { skip: !userProfile?.id });

  const checkInOutButtonLabel = isCheckedIn ? t('Check Out') : t('Check In');

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="justify-self-center flex flex-col justify-center  max-w-lg w-full bg-gray-900 dark:bg-gray-200 rounded-lg">
        <div className="space-y-6">
          <Scanner
            styles={{ container: { borderRadius: '8px' } }}
            onScan={onDecodeCallback}
          />
        </div>
      </div>
      {!!userProfile?.id && user && (
        <div className="flex flex-col items-start max-w-lg w-full">
          <p className="text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400 my-4">
            {t('User successfully scanned.')}
          </p>
          <div className="flex flex-row gap-2">
            <CheckInHandler onCheckInOut={() => setUser(undefined)}>
              {({ isProcessing, checkInOrOut }) => {
                const isLoading =
                  isFetchingUserProfile ||
                  isProcessing ||
                  isLoadingCheckedInState;
                return (
                  <Button
                    outline
                    pill
                    isProcessing={isLoading}
                    disabled={isLoading}
                    onClick={() => checkInOrOut(userProfile.id)}
                  >
                    <HiPencilAlt className="h-6 w-6" />
                    {isLoading ? null : checkInOutButtonLabel}
                  </Button>
                );
              }}
            </CheckInHandler>
            <Button
              color="gray"
              outline
              pill
              isProcessing={isFetchingUserProfile}
              disabled={isFetchingUserProfile}
              onClick={() => setUser(undefined)}
            >
              <HiX className="h-6 w-6" />
              {t('Cancel')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
