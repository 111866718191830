import { Button } from 'flowbite-react';
import { HiX } from 'react-icons/hi';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FrontdeskPinInput from './FrontdeskPinInput';
import FrontdeskQRCodeScanner from './FrontdeskQRCodeScanner';
import {
  useUsersExitFrontdeskModeCreateMutation,
  useUsersMeRetrieveQuery,
} from '../../store/finegym-rtk-query-api';
import SilentAuthProvider, {
  TriggerSilentAuthFN,
} from '../../components/hoc/SilentAuthProvider';
import PageLoadingSpinner from '../../components/PageLoadingSpinner';

export function Frontdesk() {
  const { t } = useTranslation();
  const [disableFrontdeskMode, { isLoading }] =
    useUsersExitFrontdeskModeCreateMutation();

  const handleExitFrontdeskMode = (triggerSilentAuth: TriggerSilentAuthFN) => {
    triggerSilentAuth(({ triggerActionCompleted, setIsLoading, value }) => {
      setIsLoading(true);
      disableFrontdeskMode({
        frontdeskMode: { password: value },
      })
        .unwrap()
        .then(triggerActionCompleted)
        .catch(() => setIsLoading(false));
    });
  };

  return (
    <div className="w-screen h-screen pt-10 px-10">
      <div className="flex flex-row flex-nowrap justify-between items-start">
        <div>
          <h1 className="mb-4 text-2xl md:text-4xl lg:text-5xl font-extrabold leading-none tracking-tight text-gray-900 dark:text-white">
            {t('Welcome to the')}{' '}
            <span className="text-blue-600 dark:text-blue-500">
              {t('self service desk.')}
            </span>
          </h1>
          <p className="text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">
            {t(
              'Check-in easily by scanning your personal QR Code, or type in your personal account pin.',
            )}
          </p>
        </div>
        <SilentAuthProvider
          renderItem={(triggerSilentAuth) => (
            <Button
              outline
              pill
              isProcessing={isLoading}
              disabled={isLoading}
              onClick={() => handleExitFrontdeskMode(triggerSilentAuth)}
            >
              <HiX className="h-6 w-6" />
            </Button>
          )}
        />
      </div>
      <div className="pt-10 lg:pt-20 pb-10 grid grid-cols-1 lg:grid-cols-2 gap-10">
        <FrontdeskQRCodeScanner />
        <FrontdeskPinInput />
      </div>
    </div>
  );
}

export default function FrontdeskWrapper() {
  const { currentData: me, isLoading } = useUsersMeRetrieveQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });
  const isFrontdeskMode = !!me?.is_in_frontdesk_mode;
  if (isLoading) return <PageLoadingSpinner />;
  return isFrontdeskMode ? <Frontdesk /> : <Navigate to="/dashboard" />;
}
