import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import type { RootState } from './types';

const rawBaseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_CLIENT_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const { accessToken, language } = (getState() as RootState).auth;
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }
    headers.set('Django-Timezone', timeZone);
    headers.set('Accept-Language', language);
    return headers;
  },
});

const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const { tenantDomain } = (api.getState() as RootState).auth;
  // gracefully handle scenarios where data to generate the URL is missing
  if (!tenantDomain) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No tenant domain received',
      },
    };
  }

  const urlEnd = typeof args === 'string' ? args : args.url;
  // construct a dynamically generated portion of the url
  const adjustedUrl = `${tenantDomain}${urlEnd}`;
  const adjustedArgs =
    typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl };
  // provide the amended url and other params to the raw base query
  return rawBaseQuery(adjustedArgs, api, extraOptions);
};

// initialize an empty api service that we'll inject endpoints into later as needed
export const rtkQueryApi = createApi({
  baseQuery: dynamicBaseQuery,
  endpoints: () => ({}),
});
