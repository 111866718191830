import { PayloadAction, ActionReducerMapBuilder } from '@reduxjs/toolkit';

export function generateBoilerplateAsyncThunkMatchers<StateType, PayloadType>(
  builder: ActionReducerMapBuilder<StateType>,
  actionsPrefix: string,
) {
  builder.addMatcher(
    (action: PayloadAction<PayloadType>) =>
      (action.type as string).startsWith(actionsPrefix) &&
      (action.type as string).endsWith('/fulfilled'),
    (state) => ({
      ...state,
      status: 'idle',
    }),
  );
  builder.addMatcher(
    (action: PayloadAction<PayloadType>) =>
      (action.type as string).startsWith(actionsPrefix) &&
      (action.type as string).endsWith('/pending'),
    (state) => ({
      ...state,
      status: 'pending',
    }),
  );
  builder.addMatcher(
    (action: PayloadAction<PayloadType>) =>
      (action.type as string).startsWith(actionsPrefix) &&
      (action.type as string).endsWith('/rejected'),
    (state, action: PayloadAction<PayloadType | undefined>) => ({
      ...state,
      status: 'failed',
      error: action.payload,
    }),
  );
}
