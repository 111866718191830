import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import './index.css';
import * as Sentry from '@sentry/react';
import App from './App';
import Providers from './Providers';

Sentry.init({
  dsn: 'https://e6e8bcdb71a90efd7e8b9f0a9bbeb498@o4505948525756416.ingest.us.sentry.io/4506901023227904',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ['localhost', /^https:\/\/api\.finegym\.io\/c\/test-1\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: process.env.NODE_ENV === 'production',
});

const rootElement = document.getElementById('root');

const options = {
  removeDefaultsFromUrl: true,
};

if (rootElement) {
  ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
      <Providers>
        <BrowserRouter>
          <QueryParamProvider adapter={ReactRouter6Adapter} options={options}>
            <App />
          </QueryParamProvider>
        </BrowserRouter>
      </Providers>
    </React.StrictMode>,
  );
} else {
  throw new Error('No root element found');
}
