import { Button, Label, TextInput } from 'flowbite-react';
import { useForm } from 'react-hook-form';
import { HiBriefcase } from 'react-icons/hi';
import { useCallback, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { signupTenantStepSchema } from '../../schemas/auth.schemas';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectSignup, setTenant } from './signupSlice';
import { checkTenant } from '../../utils/checkTenant';

type Props = {
  readonly onNextStepClick: () => void;
};

type Inputs = {
  tenantName: string;
  tenantDomain: string;
};

export default function SignUpTenantStep({ onNextStepClick }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { registrantData } = useAppSelector(selectSignup);
  const [isLoading, setIsLoading] = useState(false);
  const schema = useMemo(() => signupTenantStepSchema(t), [t]);

  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(
    async (action: string) => {
      if (!executeRecaptcha) {
        return;
      }
      await executeRecaptcha(action);
    },
    [executeRecaptcha],
  );

  const {
    register,
    formState: { errors, touchedFields },
    handleSubmit,
    setValue,
    getValues,
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: registrantData,
  });

  const submit = useCallback(
    async (formValues: Inputs) => {
      await handleReCaptchaVerify('signUpTenantStep');
      setIsLoading(true);
      checkTenant(formValues)
        .then(() => toast.error(t('Tenant domain already in use.')))
        .catch(() => {
          dispatch(setTenant(formValues));
          onNextStepClick();
        })
        .finally(() => setIsLoading(false));
    },
    [dispatch, handleReCaptchaVerify, onNextStepClick, t],
  );

  return (
    <div>
      <h1 className="mb-4 text-2xl font-extrabold tracking-tight text-gray-900 leding-tight dark:text-white">
        {t('Setup Your Fitness Institution Profile')}
      </h1>
      <p className="font-light text-gray-500 dark:text-gray-400 mb-4">
        {t(
          'Please provide the unique details of your gym to create its profile. This information will help distinguish your gym in our system.',
        )}
      </p>
      <form onSubmit={handleSubmit(submit)}>
        <div>
          <div className="mb-4">
            <Label htmlFor="tenantName" className="block mb-2">
              {t('Fitness Institution Name')}
            </Label>
            <TextInput
              {...register('tenantName', {
                onChange() {
                  if (!touchedFields.tenantDomain) {
                    const tenantName = getValues('tenantName');
                    setValue(
                      'tenantDomain',
                      tenantName.toLowerCase().split(' ').join('-'),
                    );
                  }
                },
              })}
              icon={HiBriefcase}
              sizing="lg"
              placeholder="Awesome Fitness GmbH"
              type="text"
              color="primary"
            />
            {errors.tenantName && (
              <p className="mt-2 text-lg text-red-600 dark:text-red-500">
                {errors.tenantName.message}
              </p>
            )}
          </div>
          <div>
            <Label htmlFor="tenantDomain" className="block mb-2">
              {t('Gym URL Identifier')}
            </Label>
            <TextInput
              {...register('tenantDomain')}
              sizing="lg"
              placeholder="awesome-fitness-gmbh"
              type="text"
              color="primary"
            />
            {errors.tenantDomain && (
              <p className="mt-2 text-lg text-red-600 dark:text-red-500">
                {errors.tenantDomain.message}
              </p>
            )}
          </div>
        </div>
        <div className="flex space-x-3 justify-end mt-10">
          <Button
            type="submit"
            className="w-full"
            color="primary"
            size="xl"
            isProcessing={isLoading}
          >
            {t('Continue')}
          </Button>
        </div>
      </form>
    </div>
  );
}
