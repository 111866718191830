import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { createContext, useCallback, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Avatar, Button, Label, Modal, TextInput } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { useUsersMeRetrieveQuery } from '../../store/finegym-rtk-query-api';

type CallbackFunction = (args: {
  readonly value: string;
  readonly triggerActionCompleted: () => void;
  readonly setIsLoading: (val: boolean) => void;
}) => void;
export type TriggerSilentAuthFN = (callback: CallbackFunction) => void;

export function createSilentAuthContext() {
  return createContext<TriggerSilentAuthFN | null>(null);
}

type Props = {
  /**
   * Optional React.Context which value is the triggerSilentAuth function
   * - Use when the silentAuth needs to track single auth state between multiple child components
   */
  readonly context?: ReturnType<typeof createSilentAuthContext>;
  readonly renderItem: (
    /**
     * Call the functionality which needs silent authentication inside this function.
     * It will be invoked after the user has authenticated succesfully
     * through the modal which will apear.
     */
    triggerSilentAuth: TriggerSilentAuthFN,
  ) => React.ReactNode;
};

export default function SilentAuthProvider({ renderItem, context }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const { data: me } = useUsersMeRetrieveQuery();
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);

  const schema = useMemo(
    () =>
      yup.object().shape({
        password: yup
          .string()
          .min(8, t('Password must be at least 8 characters'))
          .required(t('Password is required')),
      }),
    [t],
  );
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
  });

  const callbackRef = useRef<CallbackFunction>();

  const clearData = useCallback(() => {
    setIsLoading(false);
    callbackRef.current = undefined;
    reset();
  }, [reset]);

  const triggerActionCompleted = useCallback(() => {
    clearData();
    setModalOpen(false);
  }, [clearData]);

  const onSubmitForm = useCallback(
    (data: { password: string }) => {
      callbackRef.current?.({
        value: data.password,
        triggerActionCompleted,
        setIsLoading,
      });
    },
    [triggerActionCompleted],
  );

  const triggerSilentAuth = useCallback((callback: CallbackFunction) => {
    callbackRef.current = callback;
    setModalOpen(true);
  }, []);

  const content = useMemo(
    () =>
      context ? (
        <context.Provider value={triggerSilentAuth}>
          {renderItem(triggerSilentAuth)}
        </context.Provider>
      ) : (
        renderItem(triggerSilentAuth)
      ),
    [context, renderItem, triggerSilentAuth],
  );

  return (
    <>
      {content}
      {modalOpen && (
        <Modal show onClose={() => setModalOpen(false)} dismissible>
          <form
            onSubmit={(e) => {
              e?.preventDefault();
              e?.stopPropagation();
              handleSubmit(onSubmitForm)(e);
            }}
          >
            <Modal.Header>
              <div className="flex items-center gap-4">
                <Avatar
                  alt={`${me?.name ?? t('User')} ${t('Profile Photo')}`}
                  img={me?.profile_photo || undefined}
                  rounded
                />
                {me?.name}
              </div>
            </Modal.Header>
            <Modal.Body>
              <Label htmlFor="password" className="block mb-2">
                {t('Enter your password to continue')}
              </Label>
              <TextInput
                {...register('password')}
                type="password"
                placeholder="••••••••"
                color="primary"
                autoComplete="off"
                autoFocus
              />
              {errors.password && (
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                  {errors.password.message}
                </p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                color="gray"
                onClick={() => triggerActionCompleted()}
                type="button"
                disabled={isLoading}
              >
                {t('Cancel')}
              </Button>
              <Button
                type="submit"
                color="primary"
                isProcessing={isLoading}
                disabled={isLoading}
              >
                {t('Submit')}
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      )}
    </>
  );
}
