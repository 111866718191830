import { useEffect } from 'react';
import { useThemeMode } from 'flowbite-react';
import { ToastContainer } from 'react-toastify';
import { bs } from 'yup-locales';
import { setLocale, defaultLocale } from 'yup';
import ReactGA from 'react-ga4';
import { useAppSelector } from './store/hooks';
import { selectAuth } from './features/auth/authSlice';
import i18n from './i18n';
import AppRoutes from './routes';
import RouteChangeTracker from './routes/RouteChangeTracker';

function App() {
  const { language } = useAppSelector(selectAuth);
  const { mode } = useThemeMode();
  const isDarkTheme = mode === 'dark';
  if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize(import.meta.env.VITE_GA4_MEASUREMENT_ID);
  }

  useEffect(() => {
    i18n.changeLanguage(language);
    setLocale(language === 'en' ? defaultLocale : bs);
  }, [language]);

  return (
    <>
      <div className="h-screen w-screen bg-white dark:bg-gray-900">
        <RouteChangeTracker />
        <AppRoutes />
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        stacked
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={isDarkTheme ? 'dark' : 'light'}
        toastClassName="!z-[9999]"
      />
    </>
  );
}

export default App;
