import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Label, TextInput } from 'flowbite-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';
import { resetPasswordConfirmSchema } from '../../schemas/auth.schemas';

import bgImage from '../../assets/gym1.jpg';
import Logo from '../../components/Logo';
import { setExpiryTimestamp } from '../../utils/utils';
import { useAuthPasswordResetConfirmCreateMutation } from '../../store/finegym-rtk-query-api';
import { handleUseFormServerValidationErrors } from '../../utils/useFormHelpers';

type Inputs = {
  password: string;
  confirmPassword: string;
};

export default function ResetPasswordConfirm() {
  const { t } = useTranslation();
  const [passwordResetConfirm, { isLoading }] =
    useAuthPasswordResetConfirmCreateMutation();
  const params = useParams<{ resetToken?: string; userId?: string }>();
  const navigate = useNavigate();
  const schema = useMemo(() => resetPasswordConfirmSchema(t), [t]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(
    async (action: string) => {
      if (!executeRecaptcha) {
        return;
      }
      await executeRecaptcha(action);
    },
    [executeRecaptcha],
  );

  const { start, isRunning } = useTimer({
    autoStart: false,
    expiryTimestamp: setExpiryTimestamp(5),
    onExpire() {
      navigate('/auth');
    },
  });

  const submit = useCallback(
    async ({ password, confirmPassword }: Inputs) => {
      await handleReCaptchaVerify('resetPasswordConfirm');
      const token = params.resetToken;
      const uid = params.userId;
      if (token && uid) {
        passwordResetConfirm({
          passwordResetConfirm: {
            new_password1: password,
            new_password2: confirmPassword,
            token,
            uid,
          },
        })
          .unwrap()
          .then(() => {
            toast.success(t('Password reset successful'), {
              toastId: 'password-reset-success',
            });
            start();
          })
          .catch((err) =>
            handleUseFormServerValidationErrors({ setError })(err),
          );
      }
    },
    [
      handleReCaptchaVerify,
      params.resetToken,
      params.userId,
      passwordResetConfirm,
      setError,
      start,
      t,
    ],
  );

  return (
    <section
      className="min-h-screen bg-no-repeat bg-cover bg-center bg-gray-700 bg-blend-multiply bg-opacity-60"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen pt:mt-0">
        <Logo color="#FFFFFF" />
        <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
          <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            {t('Reset Password')}
          </h2>
          <form
            className="mt-4 space-y-4 lg:mt-5 md:space-y-5"
            onSubmit={handleSubmit(submit)}
          >
            <div>
              <Label htmlFor="password" className="block mb-2">
                {t('New password')}
              </Label>
              <TextInput
                {...register('password')}
                placeholder="••••••••"
                type="password"
                color="primary"
              />

              {errors.password && (
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                  {errors.password.message}
                </p>
              )}
            </div>
            <div>
              <Label htmlFor="confirm-password" className="block mb-2">
                {t('Confirm password')}
              </Label>
              <TextInput
                {...register('confirmPassword')}
                placeholder="••••••••"
                type="password"
                color="primary"
              />
              {errors.confirmPassword && (
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                  {errors.confirmPassword.message}
                </p>
              )}
            </div>
            {isRunning && (
              <p className="font-body dark:text-white">
                <span className="text-green-500">
                  {t('Password reset successfull.')}
                </span>
                <br />
                {t('Redirecting to login page...')}
              </p>
            )}
            <Button
              type="submit"
              className="w-full"
              isProcessing={isLoading}
              size="md"
              color="primary"
            >
              <p>{t('Set new password')}</p>
            </Button>
          </form>
        </div>
      </div>
    </section>
  );
}
