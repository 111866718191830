import { Footer } from 'flowbite-react';
import type { PropsWithChildren } from 'react';
import { MdFacebook } from 'react-icons/md';
import { FaDribbble, FaGithub, FaInstagram, FaTwitter } from 'react-icons/fa';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Navbar from '../components/Nav';
import Sidebar from '../components/Sidebar';
import { SidebarProvider, useSidebarContext } from '../context/SidebarContext';
import CheckedInMembersDrawer from '../components/CheckedInMembersDrawer';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setCheckedInMembersDrawerOpen } from '../features/dashboard/dashboardSlice';
import SubscriptionAlert from '../components/SubscriptionAlert';

const brandName = 'Finegym.io';

function MainContentFooter() {
  const { t } = useTranslation();
  return (
    <>
      <Footer container>
        <div className="flex w-full flex-col gap-y-6 lg:flex-row lg:justify-between lg:gap-y-0">
          <Footer.LinkGroup>
            <Footer.Link href="#" className="mr-3 mb-3 lg:mb-0">
              {t('Terms and conditions')}
            </Footer.Link>
            <Footer.Link href="#" className="mr-3 mb-3 lg:mb-0">
              {t('Privacy Policy')}
            </Footer.Link>
            <Footer.Link href="#" className="mr-3">
              {t('Licensing')}
            </Footer.Link>
            <Footer.Link href="#" className="mr-3">
              {t('Cookie Policy')}
            </Footer.Link>
            <Footer.Link href="#">{t('Contact')}</Footer.Link>
          </Footer.LinkGroup>
          <Footer.LinkGroup>
            <div className="flex gap-4 md:gap-0">
              <Footer.Link
                href="#"
                className="hover:[&>*]:text-black dark:hover:[&>*]:text-gray-300"
              >
                <MdFacebook className="text-lg" />
              </Footer.Link>
              <Footer.Link
                href="#"
                className="hover:[&>*]:text-black dark:hover:[&>*]:text-gray-300"
              >
                <FaInstagram className="text-lg" />
              </Footer.Link>
              <Footer.Link
                href="#"
                className="hover:[&>*]:text-black dark:hover:[&>*]:text-gray-300"
              >
                <FaTwitter className="text-lg" />
              </Footer.Link>
              <Footer.Link
                href="#"
                className="hover:[&>*]:text-black dark:hover:[&>*]:text-gray-300"
              >
                <FaGithub className="text-lg" />
              </Footer.Link>
              <Footer.Link
                href="#"
                className="hover:[&>*]:text-black dark:hover:[&>*]:text-gray-300"
              >
                <FaDribbble className="text-lg" />
              </Footer.Link>
            </div>
          </Footer.LinkGroup>
        </div>
      </Footer>
      <p className="my-8 text-center text-sm text-gray-500 dark:text-gray-300">
        &copy; {new Date().getFullYear()} {}
        {brandName} {t('All rights reserved')}
      </p>
    </>
  );
}

function MainContent({
  children,
  isFooter,
}: PropsWithChildren<NavbarSidebarLayoutProps>) {
  const { isOpenOnSmallScreens: isSidebarOpen } = useSidebarContext();

  return (
    <main
      className={classNames(
        'flex flex-col w-full h-full bg-gray-50 dark:bg-gray-900',
        isSidebarOpen ? 'lg:ml-16' : 'lg:ml-64',
      )}
    >
      <SubscriptionAlert />
      <div className="flex-1 overflow-y-auto h-full w-full">{children}</div>
      {isFooter && (
        <div className="mx-4 mt-4">
          <MainContentFooter />
        </div>
      )}
    </main>
  );
}

interface NavbarSidebarLayoutProps {
  isFooter?: boolean;
}

function NavbarSidebarLayout({
  children,
  isFooter,
}: PropsWithChildren<NavbarSidebarLayoutProps>) {
  const dispatch = useAppDispatch();
  const { isCheckedInMembersDrawerOpen } = useAppSelector(
    (state) => state.dashboard,
  );

  return (
    <SidebarProvider>
      <Navbar />
      <div className="flex items-start pt-16 h-screen">
        <Sidebar />
        <MainContent isFooter={isFooter}>{children}</MainContent>
        <CheckedInMembersDrawer
          open={isCheckedInMembersDrawerOpen}
          handleClose={() => dispatch(setCheckedInMembersDrawerOpen(false))}
        />
      </div>
    </SidebarProvider>
  );
}

export default NavbarSidebarLayout;
