import {
  ClientRead,
  Country,
  CountryEnum,
  Currency,
  StripePrice,
  StripeProduct,
} from '../store/finegym-rtk-query-api';

export function checkTenant({ tenantDomain }: { tenantDomain: string }) {
  return fetch(
    `${
      import.meta.env.VITE_API_BASE_URL
    }/c/${tenantDomain}/api/connection-test/`,
  ).then((res) => res.json() as Promise<ClientRead>);
}

export function searchTenants({ query }: { query: string }) {
  return fetch(
    `${import.meta.env.VITE_API_BASE_URL}/api/clients/?search=${query}`,
  ).then((res) => res.json() as Promise<ApiResponse<SearchTenantsResult>>);
}

export function getCountries() {
  return fetch(`${import.meta.env.VITE_API_BASE_URL}/api/countries/`).then(
    (res) => res.json() as Promise<Country[]>,
  );
}

export function getCurrencies() {
  return fetch(`${import.meta.env.VITE_API_BASE_URL}/api/currencies/`).then(
    (res) => res.json() as Promise<Currency[]>,
  );
}

export function getStripePrices() {
  return fetch(`${import.meta.env.VITE_API_BASE_URL}/api/stripe-prices/`).then(
    (res) => res.json() as Promise<StripePrice[]>,
  );
}

export function getStripeProduct(id: string) {
  return fetch(
    `${import.meta.env.VITE_API_BASE_URL}/api/stripe-products/${id}/`,
  ).then((res) => res.json() as Promise<StripeProduct>);
}

type Timezone = {
  code: string;
  name: string;
};

export function getTimezones({ country }: { country: CountryEnum }) {
  return fetch(
    `${import.meta.env.VITE_API_BASE_URL}/api/timezones/?country_code=${country}`,
  ).then((res) => res.json() as Promise<Timezone[]>);
}

export function getCurrenciesForCountry({ country }: { country: CountryEnum }) {
  return fetch(
    `${
      import.meta.env.VITE_API_BASE_URL
    }/api/currencies-for-country/?country=${country}`,
  ).then((res) => res.json() as Promise<Currency[]>);
}

export type ApiResponse<T> = {
  count: number;
  next: null | string;
  previous: null | string;
  results: T[];
};

export type SearchTenantsResult = {
  name: string;
  email: string;
  domain: string;
};
