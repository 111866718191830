import { Transition, Dialog, Menu } from '@headlessui/react';
import { EllipsisVerticalIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import InfiniteScroll from 'react-infinite-scroll-component';
import { uniqBy } from 'lodash';
import {
  AttendancePolymorphicRead,
  AttendanceRead,
  useLazyAttendancesListQuery,
  useMembersRetrieveQuery,
} from '../store/finegym-rtk-query-api';

import avatarPlaceholder from '../assets/avatar_placeholder.png';
import CheckInHandler from './CheckInHandler';

interface AttendanceItemProps {
  readonly attendance: AttendanceRead;
  readonly onCheckout?: () => void;
}

function AttendanceItem({ attendance, onCheckout }: AttendanceItemProps) {
  const { data: memberData } = useMembersRetrieveQuery({ id: attendance.user });
  const { t } = useTranslation();
  return (
    <li>
      <div className="group relative flex items-center px-5 py-6 dark:bg-gray-800">
        <Link
          to={`/dashboard/members/${memberData?.id}`}
          className="-m-1 block flex-1 p-1"
        >
          <div
            className="absolute inset-0 group-hover:bg-gray-50 dark:group-hover:bg-gray-600"
            aria-hidden="true"
          />
          <div className="relative flex min-w-0 flex-1 items-center">
            <span className="relative inline-block flex-shrink-0">
              <img
                className="h-10 w-10 rounded-full object-cover"
                src={memberData?.profile_photo ?? avatarPlaceholder}
                alt=""
              />
              <span
                className="bg-green-400 absolute top-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white dark:ring-gray-800"
                aria-hidden="true"
              />
            </span>
            <div className="ml-4 truncate">
              <p className="truncate text-sm font-medium text-gray-900 dark:text-gray-100">
                {memberData?.name}
              </p>
            </div>
          </div>
        </Link>
        <Menu
          as="div"
          className="relative ml-2 inline-block flex-shrink-0 text-left"
        >
          <Menu.Button className="group relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-white dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800">
            <span className="absolute -inset-1.5" />
            <span className="sr-only">{t('Open options menu')}</span>
            <span className="flex h-full w-full items-center justify-center rounded-full">
              <EllipsisVerticalIcon
                className="h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:text-gray-200 dark:group-hover:text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Menu.Button>
          <CheckInHandler>
            {({ checkInOrOut }) => (
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-9 top-0 z-10 w-48 origin-top-right rounded-md bg-white dark:bg-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={`/dashboard/members/${memberData?.id}`}
                          className={clsx(
                            active
                              ? 'bg-gray-100 text-gray-900 dark:bg-gray-600 dark:text-white'
                              : 'text-gray-700 dark:text-gray-300',
                            'block px-4 py-2 text-sm w-full text-left',
                          )}
                        >
                          {t('View profile')}
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => {
                            if (memberData?.id) {
                              checkInOrOut(memberData.id).then(onCheckout);
                            }
                          }}
                          type="button"
                          className={clsx(
                            active
                              ? 'bg-gray-100 text-gray-900 dark:bg-gray-600 dark:text-white'
                              : 'text-gray-700 dark:text-gray-300',
                            'block px-4 py-2 text-sm w-full text-left',
                          )}
                        >
                          {t('Check out')}
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            )}
          </CheckInHandler>
        </Menu>
      </div>
    </li>
  );
}

export default function CheckedInMembersDrawer({
  open,
  handleClose,
}: {
  readonly open: boolean;
  readonly handleClose: () => void;
}) {
  const { t } = useTranslation();
  const [getAttendanceList] = useLazyAttendancesListQuery();
  const [modalFullyVisible, setModalFullyVisible] = useState(false);

  const [page, setPage] = useState<number>(1);
  const [data, setData] = useState<AttendancePolymorphicRead[]>([]);
  const [dataLength, setDataLength] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const fetchData = useCallback(
    async (p: number) => {
      const res = await getAttendanceList({
        page: p,
        checkOutIsnull: true,
      }).unwrap();

      setData((curr) =>
        uniqBy([...curr, ...(res?.results || [])], (item) => item.id),
      );
      setHasMore(!!res?.next);
      setDataLength(res?.count || 0);
    },
    [getAttendanceList],
  );

  useEffect(() => {
    if (open) {
      fetchData(page);
    } else if (!modalFullyVisible) {
      setPage(1);
      setData([]);
      setDataLength(0);
      setHasMore(false);
    }
  }, [fetchData, modalFullyVisible, open, page]);

  const handleAttendanceAfterCheckout = (attendanceId: number) => {
    setData((curr) => curr.filter((item) => item.id !== attendanceId));
    setDataLength((curr) => curr - 1);
  };

  return (
    <Transition.Root
      show={open}
      afterEnter={() => setModalFullyVisible(true)}
      afterLeave={() => setModalFullyVisible(false)}
      as={Fragment}
    >
      <Dialog as="div" className="absolute !z-[9998]" onClose={handleClose}>
        <div className="fixed inset-0 bg-black bg-opacity-25 dark:bg-opacity-75" />
        {/* Backdrop effect */}
        {/* <div className="fixed inset-0" /> */}

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-gray-800 shadow-xl">
                    <div className="flex min-h-0 flex-1 flex-col py-6">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-200">
                            {t('Checked-in members')}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-white dark:bg-gray-800 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                              onClick={handleClose}
                            >
                              <span className="sr-only">
                                {t('Close panel')}
                              </span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        className="relative mt-6 flex-1 px-4 sm:px-6 overflow-y-auto"
                        id="scrollableDiv"
                      >
                        {/* Your content */}
                        <div className="flow-root">
                          <InfiniteScroll
                            scrollableTarget="scrollableDiv"
                            dataLength={dataLength}
                            next={() => {
                              setPage((p) => p + 1);
                            }}
                            hasMore={hasMore}
                            loader={<h4>Loading...</h4>}
                          >
                            <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                              {dataLength ? (
                                data.map((attendance) => (
                                  <AttendanceItem
                                    attendance={attendance}
                                    key={attendance.id}
                                    onCheckout={() => {
                                      handleAttendanceAfterCheckout(
                                        attendance.id,
                                      );
                                    }}
                                  />
                                ))
                              ) : (
                                <p className="dark:text-white">
                                  {t('No members currently in the gym')}
                                </p>
                              )}
                            </ul>
                          </InfiniteScroll>
                        </div>
                      </div>
                    </div>
                    {/* <div className="flex flex-shrink-0 justify-end px-4 py-4"> */}
                    {/* Footer */}
                    {/* </div> */}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
