import { Button, Card, Label, Spinner, Table, TextInput } from 'flowbite-react';
import { useMemo, useState } from 'react';
import { HiPlus, HiSearch } from 'react-icons/hi';
import { debounce } from 'lodash';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params';
import {
  DigitalDocumentRead,
  useDigitalDocumentsListQuery,
} from '../../../../store/finegym-rtk-query-api';
import TablePagination from '../../../../components/TablePagination';
import NoData from '../../../../components/NoData';
import StatusIndicator from '../../../../components/StatusIndicator';

type AllDocumentsTableProps = {
  readonly data: DigitalDocumentRead[];
};
function AllDocumentsTable({ data }: AllDocumentsTableProps) {
  const { t } = useTranslation();
  return (
    <Table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
      <Table.Head className="bg-gray-100 dark:bg-gray-700">
        <Table.HeadCell>{t('Title')}</Table.HeadCell>
        <Table.HeadCell>{t('Attach to')}</Table.HeadCell>
        <Table.HeadCell>{t('Is active')}</Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800 text-gray-900 dark:text-white">
        {data.map((digitalDocumentEntry) => (
          <Table.Row
            key={digitalDocumentEntry.id}
            className="hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <Table.Cell>
              <Link
                className="text-blue-600 dark:text-blue-500 hover:underline font-medium"
                to={`/dashboard/business-settings/documents/${digitalDocumentEntry.id}`}
              >
                {digitalDocumentEntry.title}
              </Link>
            </Table.Cell>
            <Table.Cell>{digitalDocumentEntry.attach_to_display}</Table.Cell>
            <Table.Cell>
              <StatusIndicator flag={digitalDocumentEntry.is_active} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

export default function DigitalDocumentListPage() {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useQueryParam('search', StringParam);
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1));

  const { data, isFetching } = useDigitalDocumentsListQuery({
    search: searchQuery ?? undefined,
    page,
  });
  const [isTyping, setIsTyping] = useState(false);

  const triggerSearch = useMemo(
    () =>
      debounce((query: string) => {
        setPage(1);
        setSearchQuery(query.trim() || undefined);
        setIsTyping(false);
      }, 500),
    [setPage, setSearchQuery],
  );

  const { toggleNextPage, togglePreviousPage } = useMemo(
    () => ({
      toggleNextPage: data?.next ? () => setPage(page + 1) : undefined,
      togglePreviousPage: data?.previous ? () => setPage(page - 1) : undefined,
    }),
    [data?.next, data?.previous, page, setPage],
  );

  const showSpinner = useMemo(
    () => isTyping || isFetching,
    [isFetching, isTyping],
  );

  return (
    <div className="col-span-full mb-4">
      <Card>
        <div className="block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
          <div className="mb-1 w-full">
            <div className="sm:flex">
              <div className="mb-3 items-center dark:divide-gray-700 sm:mb-0 sm:flex sm:divide-x sm:divide-gray-100">
                <div className="lg:pr-3">
                  <Label htmlFor="class-search" className="sr-only">
                    {t('Search')}
                  </Label>
                  <div className="relative mt-1 lg:w-64 xl:w-96 flex flex-nowrap items-center justify-start">
                    <TextInput
                      className="w-full"
                      id="class-search"
                      name="class-search"
                      placeholder={t('Search for digital documents')}
                      icon={HiSearch}
                      type="text"
                      color="primary"
                      onChange={(e) => {
                        setIsTyping(true);
                        triggerSearch(e.target.value);
                      }}
                    />
                    <Spinner
                      className={clsx(['ms-2', !showSpinner && 'invisible'])}
                      size="lg"
                      color="primary"
                    />
                  </div>
                </div>
              </div>
              <div className="ml-auto flex items-center space-x-2 sm:space-x-3">
                <Button
                  color="primary"
                  as={Link}
                  to="/dashboard/business-settings/documents/create"
                >
                  <div className="flex items-center gap-x-3">
                    <HiPlus className="text-xl" />
                    {t('Create digital document')}
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
        {data?.count || showSpinner ? (
          <>
            <div className="flex flex-col flex-1">
              <div className="overflow-x-auto">
                <div className="inline-block min-w-full align-middle">
                  <div className="overflow-hidden">
                    <AllDocumentsTable data={data?.results ?? []} />
                  </div>
                </div>
              </div>
            </div>
            <TablePagination
              count={data?.count ?? 0}
              onNextPageClick={toggleNextPage}
              onPreviousPageClick={togglePreviousPage}
              page={page}
            />
          </>
        ) : (
          <NoData />
        )}
      </Card>
    </div>
  );
}
