import { Navigate, Route, Routes } from 'react-router-dom';
import NotFound from '../components/NotFound';
import { selectAuth } from '../features/auth/authSlice';
import { useAppSelector } from '../store/hooks';
import FrontdeskWrapper from '../features/frontdesk';
import DashboardWrapper from '../features/dashboard';
import ProtectedRoute from '../guards/ProtectedRoute';
import { generalRoutes } from './general.routes';
import { authRoutes } from './auth.routes';
import { dashboardRoutes } from './dashboard.routes';
import Error403 from '@/components/403';

export default function AppRoutes() {
  const { isAuthenticated } = useAppSelector(selectAuth);

  return (
    <Routes>
      <Route
        index
        element={
          <Navigate to={isAuthenticated ? 'dashboard' : 'auth'} replace />
        }
      />

      {/* Routes available only when authenticated */}
      <Route element={<ProtectedRoute isAllowed={isAuthenticated} />}>
        <Route path="frontdesk" element={<FrontdeskWrapper />} />
        <Route path="dashboard" element={<DashboardWrapper />}>
          {dashboardRoutes}
        </Route>
      </Route>
      {/* Auth routes: signup, signin, password reset */}
      {authRoutes(isAuthenticated)}
      {generalRoutes}
      <Route path="/403" element={<Error403 />} />
      {/* This one needs to be at the bottom */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
