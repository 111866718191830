import { PropsWithChildren, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useHasPermission } from '@/components/hoc/UserGroupContext';

export default function PermissionGuard({
  permissions,
  children,
}: PropsWithChildren<{ permissions: string[] }>) {
  const hasMemberPermission = useHasPermission(permissions);

  useEffect(() => {
    if (!hasMemberPermission) {
      toast.error('You do not have permission to access this page.', {
        toastId: 'permission-error',
      });
    }
  }, [hasMemberPermission]);

  if (!hasMemberPermission) {
    return <Navigate to="/" replace />;
  }

  return children ?? <Outlet />;
}
