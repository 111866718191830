import { useState } from 'react';
import 'react-simple-keyboard/build/css/index.css';
import { useTranslation } from 'react-i18next';
import NumericKeyboardWrapper from './NumericKeyboardWrapper';
import CheckInHandler from '../../components/CheckInHandler';

export default function FrontdeskPinInput() {
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation();

  return (
    <div className="justify-self-center flex flex-col justify-center max-w-lg w-full">
      <div className="bg-gray-900 dark:bg-gray-200 h-72 resize-none  text-6xl text-center w-full rounded-t-md flex items-center justify-center">
        {inputValue ? (
          <p className="text-white dark:text-gray-950">{inputValue}</p>
        ) : (
          <p className="text-gray-600">{t('Enter your pin')}</p>
        )}
      </div>
      <CheckInHandler>
        {({ checkInOrOutWithPinCode }) => (
          <NumericKeyboardWrapper
            onChange={setInputValue}
            onEnter={() => checkInOrOutWithPinCode(inputValue)}
            maxLength={6}
          />
        )}
      </CheckInHandler>
    </div>
  );
}
