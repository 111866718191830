import { Avatar, DarkThemeToggle, Dropdown, Navbar } from 'flowbite-react';
import { HiMenuAlt1, HiX } from 'react-icons/hi';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSidebarContext } from '../context/SidebarContext';
import isSmallScreen from '../helpers/is-small-screen';
import useAuth from '../hooks/useAuth';
import { useUsersMeRetrieveQuery } from '../store/finegym-rtk-query-api';
import logo from '../assets/finegym-logo.svg';
import { AppDrawerDropdown } from './AppDrawerDropdown';

function UserDropdown() {
  const { tenantDomain, signOut } = useAuth();
  const { data: user } = useUsersMeRetrieveQuery();
  const { t } = useTranslation();

  return (
    <Dropdown
      arrowIcon={false}
      inline
      label={
        <span>
          <span className="sr-only">{t('User menu')}</span>
          <Avatar
            alt={`${user?.name ?? t('User')} ${t('Profile Photo')}`}
            img={user?.profile_photo || undefined}
            rounded
            size="sm"
          />
        </span>
      }
    >
      <Dropdown.Header>
        <span className="block truncate text-xs mb-2">{tenantDomain}</span>
        <span className="block text-sm">{user?.name}</span>
        <span className="block truncate text-sm font-medium">
          {user?.email}
        </span>
      </Dropdown.Header>
      <NavLink to="/dashboard">
        <Dropdown.Item>{t('Dashboard')}</Dropdown.Item>
      </NavLink>
      <NavLink to="/dashboard/account-settings">
        <Dropdown.Item>{t('Account Settings')}</Dropdown.Item>
      </NavLink>
      <Dropdown.Divider />
      <Dropdown.Item onClick={signOut}>{t('Sign out')}</Dropdown.Item>
    </Dropdown>
  );
}

export default function NavbarContainer() {
  const { t } = useTranslation();
  const { isOpenOnSmallScreens, isPageWithSidebar, setOpenOnSmallScreens } =
    useSidebarContext();

  return (
    <Navbar fluid>
      <div className="w-full p-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            {isPageWithSidebar && (
              <button
                type="button"
                onClick={() => setOpenOnSmallScreens(!isOpenOnSmallScreens)}
                className="mr-3 cursor-pointer rounded p-2 text-gray-600 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:inline"
              >
                <span className="sr-only">{t('Toggle sidebar')}</span>
                {isOpenOnSmallScreens && isSmallScreen() ? (
                  <HiX className="h-6 w-6" />
                ) : (
                  <HiMenuAlt1 className="h-6 w-6" />
                )}
              </button>
            )}
            <Navbar.Brand href="/">
              <img alt="" src={logo} className="mr-3 h-6 sm:h-8" />
              <span className="self-center whitespace-nowrap text-2xl font-semibold dark:text-white">
                {t('Finegym')}
              </span>
            </Navbar.Brand>
          </div>
          <div className="flex items-center lg:gap-3">
            <div className="flex items-center">
              <AppDrawerDropdown />
              <DarkThemeToggle />
            </div>
            <div>
              <UserDropdown />
            </div>
          </div>
        </div>
      </div>
    </Navbar>
  );
}
