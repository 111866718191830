import { SingleValue } from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';
import { Label, Button } from 'flowbite-react';
import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useAppDispatch } from '../../store/hooks';
import { setTenantDomain } from './authSlice';
import { checkTenant, searchTenants } from '../../utils/checkTenant';
import { setTenantSchema } from '../../schemas/auth.schemas';

interface OptionType {
  value: string;
  label: string;
}

const debouncedSearchTenants = debounce(
  (inputValue: string, callback: (options: OptionType[]) => void) => {
    searchTenants({ query: inputValue })
      .then(({ results }) =>
        results.map((r) => ({ value: r.domain, label: r.name })),
      )
      .then(callback);
  },
  1000,
);

export default function AuthSetTenantForm() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const schema = useMemo(() => setTenantSchema(t), [t]);

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<{ tenantDomain: string }>({
    resolver: yupResolver(schema),
  });

  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(
    async (action: string) => {
      if (!executeRecaptcha) {
        return;
      }
      await executeRecaptcha(action);
    },
    [executeRecaptcha],
  );

  const submit = useCallback(
    async (inputs: { tenantDomain: string }) => {
      await handleReCaptchaVerify('authSetTenant');
      setIsLoading(true);
      checkTenant(inputs)
        .then(() => {
          dispatch(setTenantDomain(inputs.tenantDomain));
        })
        .catch(() =>
          toast.error(t('Choosen fitness institution does not exist.')),
        )
        .finally(() => setIsLoading(false));
    },
    [dispatch, handleReCaptchaVerify, t],
  );

  const formatOptionLabel = (
    item: SingleValue<{ value: string; label: string }>,
  ) => {
    if (item) {
      const { label, value } = item;
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>{label}</span>
          <small>
            <strong className="text-gray-500">{t('URL Identifier')}</strong>
            {': '}
            {value}
          </small>
        </div>
      );
    }
    return null;
  };

  return (
    <form
      className="w-full max-w-md space-y-4 md:space-y-6 xl:max-w-xl"
      onSubmit={handleSubmit(submit)}
    >
      <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
        {t('Welcome back')}
      </h1>
      <div>
        <Label htmlFor="tenantName" className="block mb-2">
          {t('Select Your Gym')}
        </Label>
        <Controller
          control={control}
          name="tenantDomain"
          render={({ field: { onChange } }) => (
            <AsyncSelect
              loadOptions={(inputValue, callback) => {
                debouncedSearchTenants(inputValue, callback);
              }}
              onChange={(
                newValue: SingleValue<{ value: string; label: string }>,
              ) => onChange(newValue?.value || '')}
              cacheOptions
              defaultOptions
              isClearable
              placeholder={t(
                'Type to find your gym or select from the list...',
              )}
              formatOptionLabel={formatOptionLabel}
              classNames={{
                control: () =>
                  '!rounded-lg p-2 text-gray-900 border border-gray-300 bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500',
                input: () => 'text-gray-900 dark:text-white',
                singleValue: () => 'dark:text-white',
              }}
            />
          )}
        />
        {errors.tenantDomain && (
          <p className="mt-2 text-lg text-red-600 dark:text-red-500">
            {errors.tenantDomain.message}
          </p>
        )}
      </div>
      <Button
        type="submit"
        className="w-full"
        color="primary"
        size="lg"
        isProcessing={isLoading}
        disabled={!isDirty}
      >
        {t('Continue')}
      </Button>
      <p className="text-md font-light text-gray-500 dark:text-gray-400">
        {t("Don't have an account?")}{' '}
        <NavLink
          to="/sign-up"
          className="font-medium text-lg text-primary-700 hover:underline dark:text-primary-400"
        >
          {t('Sign up')}
        </NavLink>
      </p>
    </form>
  );
}
