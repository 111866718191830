import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NavLink } from 'react-router-dom';
import { Button, Label, TextInput } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { authSchema } from '../../schemas/auth.schemas';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  clearTenantDomain,
  selectAuth,
  setAccessToken,
  setLanguage,
} from './authSlice';
import {
  rtkQueryApi,
  useAuthLoginCreateMutation,
} from '../../store/finegym-rtk-query-api';
import { handleUseFormServerValidationErrors } from '../../utils/useFormHelpers';

type Inputs = {
  email: string;
  password: string;
};

export default function AuthSignInForm() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { tenantDomain } = useAppSelector(selectAuth);
  const [isLoading, setIsLoading] = useState(false);
  const [login] = useAuthLoginCreateMutation();
  const schema = useMemo(() => authSchema(t), [t]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(
    async (action: string) => {
      if (!executeRecaptcha) {
        return;
      }
      await executeRecaptcha(action);
    },
    [executeRecaptcha],
  );

  const submit = useCallback(
    async (inputs: Inputs) => {
      await handleReCaptchaVerify('authSignInStep');
      setIsLoading(true);
      login({
        tokenObtainPair: {
          email: inputs.email,
          password: inputs.password,
        },
      })
        .unwrap()
        .then((res) => dispatch(setAccessToken(res.access)))
        .then(() => dispatch(rtkQueryApi.endpoints.usersMeRetrieve.initiate()))
        .then((res) => {
          dispatch(setLanguage(res.data?.language || 'en'));
        })
        .catch((err) => handleUseFormServerValidationErrors({ setError })(err))
        .finally(() => setIsLoading(false));
    },
    [dispatch, handleReCaptchaVerify, login, setError],
  );
  return (
    <form
      className="w-full max-w-md space-y-4 md:space-y-6 xl:max-w-xl"
      onSubmit={handleSubmit(submit)}
    >
      <div className="flex flex-col">
        <div className="flex flex-row justify-between">
          <p className="text-md text-gray-900 dark:text-white">
            <strong>{t('Institution ID')}</strong>
            {': '}
            {tenantDomain}
          </p>
          <button
            type="button"
            className="font-medium text-primary-600 dark:text-primary-500 flex flex-row flex-nowrap"
            onClick={() => dispatch(clearTenantDomain())}
          >
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
            {t('Change institution')}
          </button>
        </div>
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          {t('Welcome back')}
        </h1>
      </div>
      <div>
        <Label htmlFor="email" className="block mb-2">
          {t('Email')}
        </Label>
        <TextInput
          {...register('email')}
          type="email"
          placeholder="Enter your email"
          color="primary"
          autoComplete="username"
        />
        {errors.email && (
          <p className="mt-2 text-sm text-red-600 dark:text-red-500">
            {errors.email.message}
          </p>
        )}
      </div>
      <div>
        <Label htmlFor="password" className="block mb-2">
          {t('Password')}
        </Label>
        <TextInput
          {...register('password')}
          type="password"
          placeholder="••••••••"
          color="primary"
          autoComplete="current-password"
        />
        {errors.password && (
          <p className="mt-2 text-sm text-red-600 dark:text-red-500">
            {errors.password.message}
          </p>
        )}
      </div>
      <div className="flex items-center justify-between">
        <NavLink
          to="/reset-password"
          className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
        >
          {t('Forgot password?')}
        </NavLink>
      </div>
      <Button
        type="submit"
        className="w-full"
        color="primary"
        isProcessing={isLoading}
      >
        {t('Sign in to your account')}
      </Button>
    </form>
  );
}
