import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../store/hooks';
import { selectAuth } from './authSlice';
import AuthSetTenantForm from './AuthSetTenantForm';
import AuthSignInForm from './AuthSignInForm';
import logo from '../../assets/finegym-logo-white.svg';

export default function Auth() {
  const { t } = useTranslation();
  const { tenantDomain } = useAppSelector(selectAuth);
  return (
    <div className="h-full grid lg:h-screen lg:grid-cols-2">
      <div className="flex items-center justify-center px-4 py-6 lg:py-0 sm:px-0">
        {!tenantDomain ? <AuthSetTenantForm /> : <AuthSignInForm />}
      </div>
      <div className="flex items-center justify-center px-4 py-6 bg-primary-600 lg:py-0 sm:px-0">
        <div className="max-w-md xl:max-w-xl">
          <NavLink
            to="/"
            className="flex items-center mb-4 text-2xl font-semibold text-white"
          >
            <img className="w-8 h-8 mr-2" src={logo} alt="logo" />
            {t('Finegym')}
          </NavLink>
          <h1 className="mb-4 text-3xl font-extrabold leading-none tracking-tight text-white xl:text-5xl">
            {t('Take charge of your fitness business with Finegym.')}
          </h1>
          <p className="mb-4 font-light text-primary-200 lg:mb-8">
            {t(
              'Join a thriving network of fitness professionals who are revolutionizing the way they run their businesses with Finegym. As the premier solution for gym and fitness center management, Finegym offers an intuitive interface, streamlined operations, and a commitment to simplicity. Make the leap to efficient management and let Finegym empower you to focus on what you do best—transforming lives through fitness.',
            )}
          </p>
          <div className="flex items-center divide-x divide-primary-500">
            <div className="flex pr-3 -space-x-4 sm:pr-5">
              <img
                className="w-10 h-10 border-2 border-white rounded-full"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png"
                alt="bonnie avatar"
              />
              <img
                className="w-10 h-10 border-2 border-white rounded-full"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/jese-leos.png"
                alt="jese avatar"
              />
              <img
                className="w-10 h-10 border-2 border-white rounded-full"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/roberta-casas.png"
                alt="roberta avatar"
              />
              <img
                className="w-10 h-10 border-2 border-white rounded-full"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/thomas-lean.png"
                alt="thomas avatar"
              />
            </div>
            <p className="pl-3 text-white sm:pl-5 dark:text-white">
              <span className="text-sm text-primary-200">
                {t('Discover why fitness professionals trust us')}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
