import { PropsWithChildren } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export default function ProtectedRoute({
  isAllowed,
  redirectPath = '/',
  children,
}: PropsWithChildren<{ isAllowed: boolean; redirectPath?: string }>) {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ?? <Outlet />;
}
