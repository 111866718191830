import { FieldValues, Path, UseFormSetError } from 'react-hook-form';
import { toast } from 'react-toastify';

interface FieldError {
  attr: string;
  code: string;
  detail: string;
}

interface ServerError {
  data?: {
    type?: string;
    errors?: FieldError[];
  };
}

export function handleUseFormServerValidationErrors<
  TFieldValues extends FieldValues,
>({ setError }: { setError: UseFormSetError<TFieldValues> }) {
  return (err: ServerError) => {
    // eslint-disable-next-line no-console
    console.log('useForm Validation Error: ', err);
    const data = err?.data;
    if (data?.type === 'validation_error' && data.errors) {
      data.errors.forEach((fieldError) => {
        if (fieldError.attr === 'non_field_errors') {
          toast.error(fieldError.detail);
        } else {
          setError(fieldError.attr as Path<TFieldValues>, {
            message: fieldError.detail,
          });
        }
      });
    }
  };
}

export function objectToFormData<EnforcedType = FormData>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: any,
  formData: FormData = new FormData(),
  parentKey?: string,
) {
  if (obj === null || obj === undefined || obj.length === 0) {
    return formData as unknown as EnforcedType;
  }

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (value === undefined) return;

    const fullKey = parentKey ? `${parentKey}${key}` : key;

    const NULL_VALUE = '';

    if (value === null) {
      formData.append(fullKey, NULL_VALUE);
    } else if (value instanceof File) {
      formData.append(fullKey, value);
    } else if (typeof value === 'object' && !Array.isArray(value)) {
      objectToFormData(value, formData, fullKey);
    } else if (Array.isArray(value)) {
      value.forEach((item, index) => {
        const arrayKey = `${fullKey}[${index}]`;
        if (typeof item === 'object' && item !== null) {
          objectToFormData(item, formData, arrayKey);
        } else if (item === null) {
          formData.append(arrayKey, NULL_VALUE);
        } else {
          // For primitive array items
          formData.append(arrayKey, item.toString());
        }
      });
    } else {
      formData.append(fullKey, value.toString());
    }
  });

  return formData as unknown as EnforcedType;
}

export const isValidImageUrlString = (value: string) =>
  /^https?:\/\/.*\.(?:png|jpg|jpeg|gif)$/.test(value);
