import { createContext, useContext, useMemo, ReactNode } from 'react';
import {
  useConnectionTestRetrieveQuery,
  useGroupsListQuery,
  useUsersMeRetrieveQuery,
} from '../../store/finegym-rtk-query-api';
import { useAppSelector } from '../../store/hooks';
import { selectAuth } from '../../features/auth/authSlice';

const UserGroupContext = createContext<string[]>([]);

export function UserGroupProvider({
  children,
}: {
  readonly children: ReactNode;
}) {
  const { isAuthenticated } = useAppSelector(selectAuth);
  const { data: user, isFetching: isFetchingUser } = useUsersMeRetrieveQuery(
    undefined,
    {
      skip: !isAuthenticated,
    },
  );
  const { data: allGroups, isFetching: isFetchingGroups } = useGroupsListQuery(
    {},
    {
      skip: !isAuthenticated,
    },
  );

  const userGroups = useMemo(() => {
    if (isFetchingUser || isFetchingGroups || !user || !allGroups) return [];
    return allGroups.results
      .filter((group) => user.groups!.includes(group.id))
      .map((group) => group.name);
  }, [user, allGroups, isFetchingUser, isFetchingGroups]);

  return (
    <UserGroupContext.Provider value={userGroups}>
      {children}
    </UserGroupContext.Provider>
  );
}

export const useUserGroups = () => {
  return useContext(UserGroupContext);
};

export const useHasPermission = (requiredGroups: string[]) => {
  const userGroups = useUserGroups();
  const { data: user } = useUsersMeRetrieveQuery();
  const { data: client } = useConnectionTestRetrieveQuery();
  // If the user is the owner, they have permission
  if (client?.email === user?.email) {
    return true;
  }
  return requiredGroups.some((group) => userGroups.includes(group));
};
