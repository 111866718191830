import { useMemo } from 'react';
import {
  useStripePaymentsSubscriptionRetrieveQuery,
  useStripePaymentsPlansRetrieveQuery,
  useStripePaymentsProductsRetrieveQuery,
  useStripePaymentsPlansListQuery,
  useStripePaymentsProductsListQuery,
  useStripePaymentsCustomerRetrieveQuery,
} from '../store/finegym-rtk-query-api';

export default function usePlan() {
  const { data: subscription, isLoading: isLoadingSubscription } =
    useStripePaymentsSubscriptionRetrieveQuery();
  const { data: customer, isLoading: isLoadingCustomer } =
    useStripePaymentsCustomerRetrieveQuery();
  const { data: plan, isLoading: isLoadingPlan } =
    useStripePaymentsPlansRetrieveQuery(
      { id: subscription?.items?.[0].plan.id },
      { skip: !subscription?.items?.[0].plan.id },
    );
  const { data: product, isLoading: isLoadingProduct } =
    useStripePaymentsProductsRetrieveQuery(
      { id: plan?.product || '' },
      { skip: !plan?.product },
    );
  const { data: availablePlans } = useStripePaymentsPlansListQuery({});
  const { data: availableProducts } = useStripePaymentsProductsListQuery({});

  const isLoadingPlanData = useMemo(
    () =>
      isLoadingSubscription ||
      isLoadingCustomer ||
      isLoadingPlan ||
      isLoadingProduct,
    [isLoadingPlan, isLoadingProduct, isLoadingSubscription, isLoadingCustomer],
  );

  return {
    availablePlans,
    availableProducts,
    current: {
      isLoading: isLoadingPlanData,
      subscription,
      customer,
      plan,
      product,
    },
  };
}
