import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Checkbox } from 'flowbite-react';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { signupTermsStepSchema } from '../../schemas/auth.schemas';
import { selectSignup, setTermsAndNewsletter } from './signupSlice';

type Inputs = {
  termsAndConditions: boolean;
};

type Props = {
  readonly onNextStepClick: () => void;
  readonly onPrevStepClick: () => void;
};

export default function SignUpTermsAndConditionsStep({
  onNextStepClick,
  onPrevStepClick,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { status } = useAppSelector(selectSignup);
  const schema = useMemo(() => signupTermsStepSchema(t), [t]);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>({
    resolver: yupResolver(schema),

    defaultValues: {
      termsAndConditions: false,
    },
  });

  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(
    async (action: string) => {
      if (!executeRecaptcha) {
        return;
      }
      await executeRecaptcha(action);
    },
    [executeRecaptcha],
  );

  const submit = useCallback(
    async (formValues: Inputs) => {
      await handleReCaptchaVerify('signUpTermsAndConditionsStep');
      dispatch(setTermsAndNewsletter(formValues));
      onNextStepClick();
    },
    [dispatch, handleReCaptchaVerify, onNextStepClick],
  );

  return (
    <>
      <h1 className="mb-4 text-2xl font-extrabold tracking-tight text-gray-900 sm:mb-6 leding-tight dark:text-white">
        {t('Terms & Conditions')}
      </h1>
      <p className="font-light text-gray-500 dark:text-gray-400 mb-4">
        {t(
          "You're almost there! Please take a moment to review the Terms of Use and Privacy Policy. These documents provide important information about your rights and responsibilities as a user of FineGym software.",
        )}
      </p>
      <form onSubmit={handleSubmit(submit)}>
        <div className="mb-6 space-y-3">
          <div className="flex items-start">
            <div className="flex items-center h-5">
              <Checkbox
                {...register('termsAndConditions')}
                aria-describedby="terms"
                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
              />
            </div>
            <div className="ml-3 text-sm">
              <label
                htmlFor="terms"
                className="font-light text-gray-500 dark:text-gray-300"
              >
                {t(
                  'By signing up, you are creating a FineGym account, and you agree to FineGym’s',
                )}{' '}
                <NavLink
                  className="font-medium text-primary-600 dark:text-primary-500 hover:underline"
                  to="terms"
                >
                  {t('Terms of Use')}
                </NavLink>{' '}
                {t('and')}{' '}
                <NavLink
                  className="font-medium text-primary-600 dark:text-primary-500 hover:underline"
                  to="policy"
                >
                  {t('Privacy Policy')}
                </NavLink>
                .
              </label>
              {errors.termsAndConditions && (
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                  {errors.termsAndConditions.message}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="flex space-x-3 justify-end mt-10">
          <Button
            type="submit"
            color="gray"
            className="w-full"
            size="lg"
            onClick={onPrevStepClick}
          >
            {t('Back')}
          </Button>
          <Button
            type="submit"
            color="primary"
            className="w-full"
            size="lg"
            isProcessing={status === 'pending'}
          >
            {t('Finish')}
          </Button>
        </div>
      </form>
    </>
  );
}
