import { Button, DarkThemeToggle, Select } from 'flowbite-react';
import { PropsWithChildren, useCallback, useState } from 'react';
import { HiCog } from 'react-icons/hi';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectAuth, setLanguage } from '../../features/auth/authSlice';
import { AppLanguages } from '../../i18n';
import useOutsideClick from '@/hooks/useOutsideClick';

export default function WithHelperButtons({ children }: PropsWithChildren) {
  const dispatch = useAppDispatch();
  const { language } = useAppSelector(selectAuth);
  const [showUtils, setShowUtils] = useState(false);

  const setLocalLanguage = useCallback(
    (val: string) => {
      dispatch(setLanguage(val));
    },
    [dispatch],
  );

  const ref = useOutsideClick<HTMLDivElement>(() => {
    setShowUtils(false);
  });

  return (
    <>
      {children}
      <div
        className="fixed z-50 text-gray-950 bottom-8 left-8 flex flex-row items-center gap-2 flex-wrap"
        ref={ref}
      >
        <Button
          className="rounded-full [&>span]:p-0 p-4 !bg-blue-500"
          onClick={() => setShowUtils((c) => !c)}
        >
          <HiCog className="w-6 h-6" />
        </Button>

        {showUtils && (
          <>
            <DarkThemeToggle className="dark:bg-gray-700 bg-white" />
            <Select
              id="local-settings-language"
              defaultValue={language}
              onChange={(e) => setLocalLanguage(e.target.value)}
              color="primary"
            >
              {Object.entries(AppLanguages).map(([code, label]) => (
                <option key={code} value={code}>
                  {label}
                </option>
              ))}
            </Select>
          </>
        )}
      </div>
    </>
  );
}
