import {
  Middleware,
  MiddlewareAPI,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { Fragment } from 'react';
import { signOutUser } from '../features/auth/authSlice';

interface ErrorDetail {
  detail?: string;
  code?: string;
}

interface ActionPayload {
  status?: number;
  data?: {
    errors?: ErrorDetail[];
  };
}

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    // eslint-disable-next-line no-console
    console.info('We got a rejected action!', action);

    const payload = action.payload as ActionPayload;
    const data = payload?.data;

    // * CASE 1
    const messages: string[] =
      data?.errors?.map((e: ErrorDetail) => e.detail ?? '') ?? [];

    if (!messages.length) {
      messages.push('Something went wrong.');
    }

    if (payload.status === 401) {
      const unauthorizedMsg =
        data?.errors?.find?.(
          (e: ErrorDetail) => e?.code === 'no_active_account',
        )?.detail ?? 'Your session has expired, please sign in again.';
      toast.error(unauthorizedMsg, {
        toastId: 'unauthorized',
      });
    } else {
      toast.error(
        <div>
          {messages.map((message, i, arr) => (
            <Fragment key={JSON.stringify(message)}>
              <p>{message}</p>
              {i < arr.length - 1 && <br />}
            </Fragment>
          ))}
        </div>,
        { toastId: JSON.stringify(messages) },
      );
    }
  }

  return next(action);
};

export const rtkQueryHandleUnauthorizedError: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      const payload = action.payload as ActionPayload;

      if (payload.status === 401) {
        api.dispatch(signOutUser());
      }
    }
    return next(action);
  };

const customMiddleware = [rtkQueryHandleUnauthorizedError, rtkQueryErrorLogger];

export default customMiddleware;
