import { Button, Dropdown, Modal } from 'flowbite-react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFillPersonCheckFill } from 'react-icons/bs';

import {
  HiViewGrid,
  HiQrcode,
  HiDesktopComputer,
  HiOutlineQuestionMarkCircle,
} from 'react-icons/hi';
import { useUsersEnterFrontdeskModeCreateMutation } from '../store/finegym-rtk-query-api';
import MemberQRCodeScannerModal from './MemberQRCodeScannerModal';
import { useAppDispatch } from '../store/hooks';
import { setCheckedInMembersDrawerOpen } from '../features/dashboard/dashboardSlice';

export function AppDrawerDropdown() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isFrontdeskDialogOpen, setIsFrontdeskDialogOpen] = useState(false);
  const [enableFrontdeskMode, { isLoading }] =
    useUsersEnterFrontdeskModeCreateMutation();
  const handleConfirmDialog = useCallback(() => {
    enableFrontdeskMode()
      .unwrap()
      .then(() => setIsFrontdeskDialogOpen(false));
  }, [enableFrontdeskMode]);

  return (
    <>
      <MemberQRCodeScannerModal
        renderButton={({ openModal: openQRCodeScannerModal }) => (
          <Dropdown
            arrowIcon={false}
            inline
            label={
              <span className="rounded-lg p-2 hover:bg-gray-100 dark:hover:bg-gray-700">
                <span className="sr-only">{t('Tools')}</span>
                <HiViewGrid className="text-2xl text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white" />
              </span>
            }
          >
            <div className="block rounded-t-lg border-b bg-gray-50 py-2 px-4 text-center text-base font-medium text-gray-700 dark:border-b-gray-600 dark:bg-gray-700 dark:text-white">
              {t('Tools')}
            </div>
            <div className="grid grid-cols-3 gap-4 p-4">
              <button
                onClick={() => setIsFrontdeskDialogOpen(true)}
                type="button"
                className="block rounded-lg p-4 text-center hover:bg-gray-100 dark:hover:bg-gray-600"
              >
                <HiDesktopComputer className="mx-auto mb-1 h-7 w-7 text-gray-500 dark:text-white" />
                <div className="text-sm font-medium text-gray-900 dark:text-white">
                  {t('Frontdesk mode')}
                </div>
              </button>
              <button
                onClick={openQRCodeScannerModal}
                type="button"
                className="block rounded-lg p-4 text-center hover:bg-gray-100 dark:hover:bg-gray-600"
              >
                <HiQrcode className="mx-auto mb-1 h-7 w-7 text-gray-500 dark:text-white" />
                <div className="text-sm font-medium text-gray-900 dark:text-white">
                  {t('Scan member')}
                </div>
              </button>
              <button
                onClick={() => dispatch(setCheckedInMembersDrawerOpen(true))}
                type="button"
                className="block rounded-lg p-4 text-center hover:bg-gray-100 dark:hover:bg-gray-600"
              >
                <BsFillPersonCheckFill className="mx-auto mb-1 h-7 w-7 text-gray-500 dark:text-white" />
                <div className="text-sm font-medium text-gray-900 dark:text-white">
                  {t('Checked-in members')}
                </div>
              </button>
            </div>
          </Dropdown>
        )}
      />
      {isFrontdeskDialogOpen && (
        <Modal
          onClose={() => setIsFrontdeskDialogOpen(false)}
          show={isFrontdeskDialogOpen}
          size="md"
          dismissible
        >
          <Modal.Header className="px-6 pb-0 pt-6">
            <span className="sr-only">{t('Enable frontdesk mode')}</span>
          </Modal.Header>
          <Modal.Body className="px-6 pb-6 pt-0">
            <div className="flex flex-col items-center gap-y-6 text-center">
              <HiOutlineQuestionMarkCircle className="text-7xl text-primary-500" />
              <p className="text-xl text-gray-500">
                {t('Are you sure you want to enter frontdesk mode?')}
              </p>
              <div className="flex items-center gap-x-3">
                <Button
                  color="gray"
                  onClick={() => setIsFrontdeskDialogOpen(false)}
                  type="button"
                >
                  {t('No, cancel')}
                </Button>
                <Button
                  color="primary"
                  onClick={handleConfirmDialog}
                  isProcessing={isLoading}
                  type="button"
                >
                  {t("Yes, I'm sure")}
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
