import { useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Timeline } from 'flowbite-react';
import { HiCheck, HiArrowRight, HiMinus } from 'react-icons/hi';
import clsx from 'clsx';
import SignUpTenantStep from './SignUpTenantStep';
import SignUpAdminStep from './SignUpAdminStep';
import SignUpTermsAndConditionsStep from './SignUpTermsAndConditionsStep';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearState, createTenantThunk, selectSignup } from './signupSlice';
import SignUpSuccess from './SignUpSuccess';
import logo from '../../assets/finegym-logo-white.svg';
import SignupPlanStep from './signupPlanStep';

export default function SignUp() {
  const { t } = useTranslation();
  const [stepIndex, setStepIndex] = useState(0);
  const dispatch = useAppDispatch();
  const { isSuccess } = useAppSelector(selectSignup);

  const singUpSteps = useMemo(
    () => [
      {
        title: t('Institution Setup'),
        component: <SignUpTenantStep onNextStepClick={() => setStepIndex(1)} />,
        description: t('Set up your institution'),
      },
      {
        title: t('Admin Account'),
        component: (
          <SignUpAdminStep
            onNextStepClick={() => setStepIndex(2)}
            onPrevStepClick={() => setStepIndex(0)}
          />
        ),
        description: t('Create an admin account'),
      },
      {
        title: t('Plan Selection'),
        component: (
          <SignupPlanStep
            onNextStepClick={() => setStepIndex(3)}
            onPrevStepClick={() => setStepIndex(1)}
          />
        ),
        description: t('Select a plan that suits your needs'),
      },
      {
        title: t('Terms & Conditions'),
        component: (
          <SignUpTermsAndConditionsStep
            onPrevStepClick={() => setStepIndex(2)}
            onNextStepClick={() => dispatch(createTenantThunk())}
          />
        ),
        description: t('Accept the terms and conditions'),
      },
    ],
    [dispatch, t],
  );

  const currentStep = useMemo(
    () => singUpSteps[stepIndex]?.component ?? <SignUpSuccess />,
    [singUpSteps, stepIndex],
  );

  useEffect(
    () =>
      function cleanUp() {
        dispatch(clearState());
      },
    [dispatch],
  );

  return (
    <section className="py-8 bg-white dark:bg-gray-900 lg:py-0">
      <div className="lg:flex">
        <div className="hidden w-full max-w-md p-12 lg:h-screen lg:flex flex-col bg-primary-600">
          <div className="flex items-center mb-8 space-x-4">
            <NavLink
              to="/"
              className="flex items-center text-2xl font-semibold text-white"
            >
              <svg
                className="w-6 h-6 mr-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              {t('Go back')}
            </NavLink>
          </div>

          <div className="w-full h-full flex flex-col justify-start mt-8 items-start gap-y-3">
            <Timeline className="border-none">
              {singUpSteps.map((step, i) => {
                return (
                  <Timeline.Item
                    key={step.title}
                    className={clsx(
                      'm-0 pl-6 pb-10',
                      i !== singUpSteps.length - 1 &&
                        'border-l border-blue-500 ',
                    )}
                  >
                    <Timeline.Point
                      // className="text-primary-600 dark:text-primary-500 bg-primary-600 dark:bg-primary-500"
                      className="[&>span]:bg-primary-500 [&>span]:dark:bg-primary-500 [&_*]:text-white [&>span]:ring-blue-800 dark:[&>span]:ring-blue-800"
                      icon={
                        stepIndex === i
                          ? HiArrowRight
                          : stepIndex <= i
                            ? HiMinus
                            : HiCheck
                      }
                    />
                    <Timeline.Content>
                      <Timeline.Time className="text-gray-200 dark:text-gray-200 ">
                        {t('Step')} {i + 1}
                      </Timeline.Time>
                      <Timeline.Title className="text-white dark:text-whitd">
                        {' '}
                        {step.title}
                      </Timeline.Title>
                      <Timeline.Body className="text-gray-200 dark:text-gray-200">
                        {step.description}
                      </Timeline.Body>
                    </Timeline.Content>
                  </Timeline.Item>
                );
              })}
            </Timeline>
          </div>
        </div>
        <div className="flex items-center mx-auto md:w-[42rem] px-4 md:px-8 xl:px-0">
          <div className="w-full">
            <div className="flex items-center justify-center mb-8 space-x-4 lg:hidden">
              <NavLink
                to="/"
                className="flex items-center text-2xl font-semibold"
              >
                <img className="w-8 h-8 mr-2" src={logo} alt="logo" />
                <span className="text-gray-900 dark:text-white">
                  {t('Finegym')}
                </span>
              </NavLink>
            </div>

            {isSuccess ? <SignUpSuccess /> : currentStep}
          </div>
        </div>
      </div>
    </section>
  );
}
